.navigation-container {
  width: 23%;
  max-width: 252px;
  flex-shrink: 0;
}
.navigation-container_top {
  width: 252px;
  position: fixed;
  top: 0;
}
.navigation-container_top-logo {
  margin: 14px 20px;
}
.navigation-container_top-logo a {
  display: block;
  width: 30px;
}
.navigation-container_top-logo img {
  width: 25px;
  height: 20px;
}
.navigation-container_top-menu {
  height: 100%;
  margin-top: 15px;
  border-bottom: 1px solid #3a3a41;
}
.navigation-container_top-subscriptions {
  height: 100%;
  padding: 25px 0;
  border-bottom: 1px solid #3a3a41;
}
.navigation-container_top-subscriptions.sign-up {
  border-bottom: none;
  padding: 15px 15px 15px 0;
}
.navigation-container_top-subscriptions.sign-up .join-container {
  background: #272734;
  border-radius: 15px;
  padding: 24px 16px;
  color: #FFFFFF;
}
.navigation-container_top-subscriptions.sign-up .join-container h3 {
  font-weight: bold;
  font-size: 18px;
}
.navigation-container_top-subscriptions.sign-up .join-container h3 .brand {
  color: #0066ff;
}
.navigation-container_top-subscriptions.sign-up .join-container .message {
  padding: 10px 0;
}
.navigation-container_top-subscriptions.sign-up .join-container .sign-up_button {
  text-transform: none;
  line-height: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #06F;
  border-radius: 8px;
}
.navigation-container_top-subscriptions.sign-up .join-container .sign-up_button:hover, .navigation-container_top-subscriptions.sign-up .join-container .sign-up_button:active {
  background-color: #1a75ff;
}
.navigation-container_top-subscriptions .section-header {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding-left: 15px;
  color: #b3b3b3;
}
.navigation-container_bottom {
  width: 252px;
  height: 60px;
  position: fixed;
  bottom: 0;
}
.navigation-container .nav-item {
  line-height: 50px;
  height: 50px;
  color: #FFFFFF;
  border-radius: 25px;
  padding: 0 30px 0 20px;
  margin: 0px 5px 10px 5px;
  width: 100%;
  text-align: left;
}
.navigation-container .nav-item:active, .navigation-container .nav-item:hover {
  text-decoration: none;
  background: #1d1d26;
}
.navigation-container .nav-item.user-nav-item {
  width: 96%;
  text-align: left;
  padding: 0 20px;
}
.navigation-container .nav-item.selected {
  background: #23232f;
  font-weight: 700;
}
.navigation-container .nav-item i, .navigation-container .nav-item span.material-icons {
  font-size: 25px;
  line-height: 50px;
  text-decoration: none;
}
.navigation-container .nav-item i.secondary-icon, .navigation-container .nav-item span.material-icons.secondary-icon {
  transform: rotate(270deg);
  font-size: 22px;
  float: right;
  line-height: 50px;
  pointer-events: none;
}
.navigation-container .nav-item .image-icon {
  width: 22px;
  height: 22px;
}
.navigation-container .nav-item .button_label {
  font-size: 18px;
  padding-left: 12px;
  line-height: 52px;
  vertical-align: top;
  text-decoration: none;
}

@media screen and (max-width: 1279px) {
  .navigation-container {
    flex-shrink: initial;
  }
  .navigation-container_top, .navigation-container_bottom {
    max-width: 230px;
    width: 100%;
  }
}
@media screen and (max-width: 1008px) {
  .navigation-container {
    flex-shrink: initial;
    position: relative;
  }
  .navigation-container_top, .navigation-container_bottom {
    max-width: 174px;
    width: 100%;
  }
  .navigation-container .nav-item .button_label {
    font-size: 16px;
  }
  .navigation-container .nav-item i.secondary-icon {
    margin-right: 0;
  }
  .navigation-container .join-container {
    margin: 0 5px;
  }
}
@media screen and (min-height: 640px) {
  .navigation-container_inner {
    overflow-y: hidden !important;
  }
}
@media screen and (max-width: 824px) {
  .navigation-container_inner {
    position: fixed;
    height: 100%;
    max-width: 25%;
  }
  .navigation-container_top, .navigation-container_bottom {
    position: relative;
    max-width: 100%;
  }
  .navigation-container_top-subscriptions {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 738px) {
  .navigation-container_inner {
    max-width: 27%;
  }
  .navigation-container .nav-item .button_label {
    font-size: 14px;
  }
  .navigation-container .join-container {
    width: 85%;
  }
}
@media screen and (max-width: 738px) {
  .navigation-container {
    flex-grow: 0;
    width: 50px;
  }
  .navigation-container_inner {
    background: #2b2b31;
    max-width: 50px;
    overflow: hidden;
    border-right: 1px solid #3a3a41;
  }
  .navigation-container .nav-item {
    border-radius: initial;
    padding: 0 10px;
    text-align: center;
    margin-bottom: 5px;
  }
  .navigation-container .nav-item .button_label {
    display: none;
  }
  .navigation-container .nav-item i.secondary-icon {
    display: none;
  }
  .navigation-container .nav-item.user-nav-item {
    padding: 0 10px;
  }
  .navigation-container_bottom .discord-btn {
    display: none;
  }
  .navigation-container_top-logo {
    margin: 14px 12px 10px;
  }
  .navigation-container_top-menu {
    margin-top: 0;
  }
  .navigation-container_top-subscriptions {
    padding: 0;
    overflow-x: hidden;
    max-height: 175px;
    width: 75px;
  }
  .navigation-container_top-subscriptions.sign-up {
    padding: 0;
  }
  .navigation-container_top-subscriptions.sign-up .join-container {
    display: none;
  }
  .navigation-container_top-subscriptions .subscription .section-header {
    display: none;
  }
}
@media screen and (max-height: 750px) {
  .navigation-container_bottom {
    display: none;
  }
}

