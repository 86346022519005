.suggestion-modal_inner {
  margin: 0 15px 15px 15px;
  display: grid;
}
.suggestion-modal_textarea {
  background-color: #121217;
  color: #FFFFFF;
  height: 200px;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  resize: none;
}
.suggestion-modal_submit-button {
  justify-self: right;
  margin-top: 10px;
  text-transform: none;
}

.modal-loading-mask {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
}

