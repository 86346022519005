@import '../../resources/scss/variables';

.explore-container {
	background: $dark-charade;
	border-right: 1px solid $tuna;
	border-left: 1px solid $tuna;

	.btn-back {
		height: 45px;
		display: block;
		line-height: 40px;
		font-size: 18px;
		padding-left: 15px;
		width: 85px;
	}
}

@media screen and (max-width: 738px){
	.explore-container {
		background: $black-russian;
	}
}