@import '../../resources/scss/variables';

.search-container {
  width: 25%;
  max-width: 290px;

  &_inner {
  }

  &_top {
    position: fixed;
  }

	&_ads {
		width: 300px;
		height: 330px;
		display: block;
		position: fixed;
		top: 65px;
		background-color: $dark-charade;
		margin: 0 0 0 20px;
		border-radius: 15px;

		.ads-title {
			padding: 5px;
			color: rgba($gray10, 0.5);
		}
	}

  &_bottom {
    position: fixed;
    bottom: 0;
    width: 300px;
  }
}

@media screen and (max-width: 1200px) {
  .search-container {
    max-width: 250px;

    &_bottom {
      width: 250px;
    }

		&_ads {
			width: 235px;
		}
  }
}
@media screen and (max-width: 1008px) {
  .search-container {
    max-width: 27%;
    width: 100%;

    &_bottom {
      max-width: 27%;
      width: 100%;

      .footer {
        .version {
          display: block;
          margin-top: 5px;
        }
      }
    }

		&_ads {
			max-width: 23%;
			width: 100%;
		}
  }
}

@media screen and (max-width: 738px) {
  .search-container {
    display: none;
  }
}