@import '../../resources/scss/variables';

.search {
  width: 300px;
  height: 45px;
  border-radius: 23px;
  background-color: $dark-charade;
  color: $white;
  transition: 0.3s ease;
  transition-property: width, background-color, color;
  z-index: 5;
  margin: 6px 20px 15px;

  &, &_inner {
    position: relative;
  }

  &_inner {
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  &_icon, &_input {
    display: inline-block;
    vertical-align: top;
  }

  &_icon, &_clear {
    text-align: center;
  }

  &_icon {
    width: 30px;
    line-height: 30px;
    padding-left: 5px;
    font-size: $font-size--medium;
    color: $shaded-text;

    i {
      line-height: inherit;
      font-size: 20px;
    }
  }

  &_input {
    width: calc(100% - 30px);
    height: 45px;
    line-height: 45px;
    padding: 0 25px 0 5px;
    border: none;
    background-color: transparent;
    font-family: $font-family--base;
    font-weight: $font-weight--normal;
    color: $white;
    transition: color 0.3s ease;

    &::placeholder {
      font-family: $font-family--base;
      font-size: 16px;
      color: $shaded-text;
    }

    &:focus {
      outline: none;
    }
  }

  &_clear {
    position: absolute;
    top: calc(50% - 10px);
    right: 5px;
    width: 20px;
    font-size: $font-size--base;
    cursor: pointer;
  }

  &--disabled & {
    pointer-events: none;

    &_inner {
      opacity: 0.3;
    }
  }
}

@media screen and (max-width: 1200px) {
  .search {
    width: 95%;
  }
}

@media screen and (max-width: 1008px) {
  .search {
    width: 95%;
    max-width: 180px;
  }
}
@media screen and (max-width: 738px) {
  .search {
    width: 80%;
  }
}