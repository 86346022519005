@import '../../../resources/scss/variables';
@import '../../../resources/scss/animations';
@import '../../../resources/scss/mixins';

.topic {
  &_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    animation: fade-in $transition-duration--default $transition-timing-function--default;
    justify-content: space-between;
    padding: 0 15px;
  }

  &_item {
    position: relative;
    flex-basis: calc(1 / 7 * 100%);
    margin-bottom: gux(4);

    &.coming-soon {
      .topic_item--shadow {
        width: 127px;
        position: absolute;
        top: 0;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    &:hover {
      .topic_tooltip {
        opacity: 1;
      }
    }

    &--no-data {
      flex-basis: 100%;
      margin: gux(4) 0;
    }

  }

  &_link,
  &_thumbnail {
    display: block;
		border-radius: 6px;
  }

  &_thumbnail {
    width: 163px;
    margin-bottom: 5px;

		&:hover, &:active {
			-webkit-filter: brightness(120%);
			filter: brightness(120%);
		}
  }

  &_tooltip {
    font-weight: bold;
    font-size: 14px;
		padding-right: 5px;
  }
}

@media screen and (max-width: 1279px) {
  .topic {
    &_thumbnail {
      width: 153px;
    }
  }
}

@media screen and (max-width: 738px) {
  .topic {
    &_list {
      //justify-content: center;
    }

    &_thumbnail {
      width: 163px;
    }
  }
}
