.footer {
  margin: 0 10px 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}
.footer_item {
  margin: 5px 0;
  font-size: 13px;
}
.footer_item.footer_social-icons {
  margin-bottom: 15px;
  font-size: 12px;
}
.footer_item.footer_social-icons .social-icons {
  color: rgba(255, 255, 255, 0.6);
}
.footer_item.footer_social-icons .social-icons .img-icon {
  width: 25px;
  margin: 0 10px;
  top: 2px;
  position: relative;
  opacity: 0.6;
}
.footer_item.footer_social-icons .social-icons .img-icon:hover, .footer_item.footer_social-icons .social-icons .img-icon:active {
  opacity: 1;
}
.footer_item.footer_social-icons .social-icons:hover, .footer_item.footer_social-icons .social-icons:active {
  color: #FFFFFF;
}
.footer_item .tattle-link.privacy-policy,
.footer_item .tattle-link.terms-of-use,
.footer_item .tattle-link.discord-bot {
  text-decoration: underline;
  color: #999999;
}
.footer_item .tattle-link.privacy-policy:hover, .footer_item .tattle-link.privacy-policy:active,
.footer_item .tattle-link.terms-of-use:hover,
.footer_item .tattle-link.terms-of-use:active,
.footer_item .tattle-link.discord-bot:hover,
.footer_item .tattle-link.discord-bot:active {
  color: #FFFFFF;
}
.footer_item .tattle-link.privacy-policy,
.footer_item .tattle-link.terms-of-use {
  margin: auto;
  width: 100px;
  display: inline-block;
}
.footer_item i {
  font-size: 25px;
  margin: 0 10px;
}
.footer .version {
  color: #999999;
}

@media screen and (max-width: 1008px) {
  .footer_item .tattle-link.privacy-policy,
  .footer_item .tattle-link.terms-of-use {
    display: block;
    padding-right: 0;
    margin-top: 5px;
  }
}
@media screen and (max-width: 734px) {
  .footer_copy {
    font-size: 12px;
  }
}

