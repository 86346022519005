@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slide-in--from-bottom {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes loading-bars {
  0%, 80%, 100% {
    box-shadow: 0 0;
    height: 32px;
  }
  40% {
    box-shadow: -16px;
    height: 40px;
  }
}
.loading-mask {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loading-mask, .loading-mask_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}
.loading-mask_overlay {
  background-color: transparent;
  transition: background-color 0.3s ease;
}
.loading-mask_spinner {
  position: relative;
  background-color: #FFFFFF;
  z-index: 51;
}
.loading-mask_spinner, .loading-mask_spinner:before, .loading-mask_spinner:after {
  width: 8px;
  height: 32px;
  animation: loading-bars 1s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
}
.loading-mask_spinner {
  animation-delay: -0.16s;
}
.loading-mask_spinner:before, .loading-mask_spinner:after {
  content: "";
  position: absolute;
  top: 0;
  background-color: inherit;
}
.loading-mask_spinner:before {
  left: -12px;
  animation-delay: -0.32s;
}
.loading-mask_spinner:after {
  left: 12px;
}
.loading-mask--fixed {
  position: fixed;
}
.loading-mask--light .loading-mask_spinner {
  background-color: rgba(0, 0, 0, 0.5);
}
.loading-mask--light .loading-mask_overlay {
  background-color: black;
  opacity: 0.25;
}
.loading-mask--dark .loading-mask_spinner {
  background-color: rgba(255, 255, 255, 0.5);
}
.loading-mask--dark .loading-mask_overlay {
  background-color: black;
  opacity: 0.25;
}

