@import '../../resources/scss/variables';

.explore-container {
	background: $dark-charade;

	.categories {
		&--no-data {
			padding: gux(2.5);
			font-size: gux(2);
		}

		.title {
			font-size: $font-size--icon;
			border-bottom: 2px solid $tuna;

			&.no-border {
				border-bottom: none;
			}
		}

		.categories-list {
			background: $dark-charade;

			.category-row {
				height: gux(40);
				padding: 10px 20px 5px;
			}
		}
	}
}

@media screen and (max-width: 738px){
	.explore-container {
		background: $black-russian;

		.categories {
			.categories-list {
				background: $black-russian;
				padding-bottom: 50px;
			}
		}
	}
}