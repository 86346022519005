@import '../../resources/scss/variables';

@mixin buttonTheme($base-background, $base-color, $hover-background, $hover-color) {
  background-color: $base-background;
  color: $base-color;

  &:hover, &:focus {
    background: $hover-background;
    color: $hover-color;
  }

  &.button--loading {
    &:focus {
      background: $base-background;
    }

    &:before {
      border-color: rgba($base-color, .3);
    }
  }

  &:disabled,
  &.button--disabled {
    background: $base-background;
    color: rgba($base-color, .3);
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &.button--not-available {
    background: $base-background;
    color: rgba($base-color, .3) !important;
    pointer-events: all !important;
  }
}

.button {
  display: inline-block;
  opacity: 1;
  border: none;
  border-radius: $border-radius--small;
  padding: 0 gux(1.5);
  height: 30px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s ease;
  cursor: pointer;

  @include buttonTheme($blue, $white, mix($blue, $dark-slate, 90%), $white);

  &:focus {
    outline: none;
  }

  &:hover {
    .tooltip {
      display: block;
    }
  }

  &_icon, &_label {
    vertical-align: middle;
    pointer-events: none;
  }

  &_icon {
    line-height: 30px;

    & + .button_label {
      margin-left: $grid-unit;
    }
  }

  &--square, &--circle {
    position: relative;
    padding: 0;
    width: 30px;

    .tooltip {
      display: none;
    }
  }

  &--circle {
    border-radius: $border-radius--circle;
  }

  &--tall {
    height: 40px;
  }

  &--tall.button--square,
  &--tall.button--circle {
    width: 40px;

    .button_icon {
      font-size: gux(3);
      line-height: 40px;
    }
  }

  &--wide {
    width: 120px;
  }

  &--cancel,
  &--transparent {
    text-transform: capitalize;

    @include buttonTheme(transparent, $light-slate, transparent, $white);
  }

  &--invisible {
    opacity: 0;
    pointer-events: none;
  }

  &--hidden {
    display: none;
  }

  &:disabled,
  &--disabled {
    pointer-events: none;
  }

  &--block {
    display: block;
    text-align: left;
  }

  &--bordered {
    border: 2px solid $blue;
    background: transparent;

    &:hover, &:focus {
      background: rgba(0, 102,204, 0.3);
      color: $white;
    }
  }
}