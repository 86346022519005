@import '../../resources/scss/variables';

.forgot-password-page {
    border-right: 1px solid $tuna;
    border-left: 1px solid $tuna;
    height: 100%;
    background: #1D1D26;

    &_inner {
        .form-message {
          color: $green;
          padding: 0 25px;
        }

        .form-error {
          color: $red;
          padding: 0 25px;
        }
    
        .update-form {
          .row {
            width: 100%;
            display: block;
            padding: 15px 20px;
            border-top: 1px solid $tuna;
            height: 50px;
    
            &:first-child {
              border-top: 0;
            }

            .field-error {
              color: $pale-pink;
            }
          }
    
          label {
            display: inline-block;
            margin-bottom: 3px;
            color: $pink-swan;
            font-size: 16px;
            padding: 0px 35px 10px 15px;
			      line-height: 16px;
            width: 120px;
          }
    
          input {
            background: #1D1D26;
            color: $white;
            height: 16x;
            font-size: 16px;
            line-height: 16px;
            width: 65%;
            border: none;
            padding: 0 10px;
          }

          .blueLink{
            position: relative;
            color: $blue-link;
            cursor: pointer;
            top: 12px;
            float: right;
            padding: 0 30px;
          }

          .icon {
            display: contents;

            .tooltip {
                position: relative;
                display: inline-block;
                border-bottom: 1px dotted black;
            }
                
            .tooltip .tooltiptext {
                visibility: hidden;
                width: 120px;
                background-color: black;
                color: #fff;
                text-align: center;
                border-radius: 6px;
                padding: 5px 0;
                right: 20px;
                top: -15px;
                
                /* Position the tooltip */
                position: absolute;
                z-index: 1;
            }
                
            .tooltip:hover .tooltiptext {
                visibility: visible;
            }
          }
          
          button {
            float: right;
            width: 100px;
            height: 40px;
            border-radius: 8px;
            background: $tuna;
            color: $white;
            border: 1px solid $tuna;
            cursor: pointer;
    
            &:hover, &:active {
              box-shadow: 0 0 2px $silver;
            }
          }
        }
      }

}

