@import '../../resources/scss/variables';

.welcome-page {
	background: $dark-charade;
	border-right: 1px solid $tuna;
	border-left: 1px solid $tuna;
	height: 100%;

	h1 {
		padding-top: 100px;
		text-align: center;
		font-size: 26px;
		font-weight: 700;
	}

	h2 {
		margin-top: 20px;
		text-align: center;
		font-size: 20px;
		font-weight: 400;
	}

	img {
		height: 35px;
		margin: 20px auto;
		display: block;
	}

	.sign-in_btn {
		text-transform: none;
		display: block;
		margin: 0 auto;
		height: 35px;
		line-height: 35px;
		background-color: $blue11;
		border-radius: 8px;

		&:hover, &:active {
			background-color: $blue12;
		}
	}
}