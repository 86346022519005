.error-page {
  height: 100%;
  background: #2b2b31;
}
.error-page .error-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.error-page .error-item_icon {
  font-size: 45px;
  margin: -80px 0 15px 0;
  color: #E66531;
}
.error-page .error-item_message {
  font-size: 16px;
}

