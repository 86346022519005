@import '../../resources/scss/variables';

.title {
  font-family: $font-family--base;
  border-bottom: 2px solid $tuna;

  &--page {
    font-size: gux(2.75);
    font-weight: $font-weight--bold;
    letter-spacing: 0.5px;
    padding: gux(2) gux(0.5);
  }

  &--topic {
    font-size: gux(3);
    font-weight: $font-weight--bold;
  }

  &--section {
    font-size: $font-size--icon;
    font-weight: $font-weight--bold;
    padding: 11px;
  }
}