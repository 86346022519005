.bot-container {
  background-size: contain;
  width: 100%;
  position: relative;
  background-color: #121217;
  border-right: 1px solid #373749;
  border-left: 1px solid #373749;
  /* Header Styles */
  /* Subscription Options Styles */
  /* Pro Features Styles */
  /* Bot FAQs Styles */
}
.bot-container .top-image-container {
  position: relative;
}
.bot-container .bot-library-image {
  width: 100%;
  opacity: 50%;
  height: auto;
  display: block;
}
.bot-container .header-overlay-text {
  position: absolute;
  /* margin: auto; */
  top: 60%;
  text-align: center;
  transform: translate(0, -50%);
  font-size: min(48px, 24px + 2vw);
  font-family: "Roboto", sans-serif;
  width: 100%;
  font-weight: bold;
}
.bot-container .bot-subscriptions-container {
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr;
}
.bot-container .bot-subscription-container {
  display: inline-block;
}
.bot-container .free-container {
  margin-left: 10%;
}
.bot-container .pro-container {
  margin-right: 10%;
}
.bot-container .bot-subscription-pro-img {
  width: 20px;
  vertical-align: top;
  margin-top: 20px;
}
.bot-container .bot-subscription-spacer {
  margin: 0;
}
.bot-container .bot-subscription-title {
  background-color: #373749;
  text-align: center;
  height: 80px;
  border-radius: 10px 10px 0 0;
  font-size: min(28px, 16px + 1vw);
  font-family: "Roboto", sans-serif;
  line-height: 80px;
}
.bot-container .bot-subscription-description {
  height: 180px;
  background: #1D1D26;
  padding: 15px;
  font-size: min(14px, 8px + 1vw);
}
.bot-container .bot-subscription-features {
  margin-top: 10px;
}
.bot-container .bot-subscription-features li {
  padding-bottom: 6px;
}
.bot-container .bot-subscription-features {
  list-style-type: disc;
  padding-left: 20px;
}
.bot-container .bot-subscription-select {
  text-align: center;
  background-color: #1D1D26;
  border-top: 1px #323232 solid;
  border-radius: 0 0 10px 10px;
  height: 120px;
  display: grid;
  grid-template-rows: 3fr 1fr 1fr;
  place-items: center;
}
.bot-container .bot-subscription-button {
  text-transform: none;
  line-height: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 4px;
  width: 80%;
  font-weight: bold;
  font-size: min(12px, 6px + 1vw);
}
.bot-container .free-button {
  background-color: #373749;
}
.bot-container .free-button:hover, .bot-container .free-button:active {
  background-color: #474759;
}
.bot-container .pro-button {
  background-color: #066AFF;
}
.bot-container .pro-button:hover, .bot-container .pro-button:active {
  background-color: #167AFF;
}
.bot-container .bot-pro-features-container {
  margin-top: 30px;
}
.bot-container .bot-pro-features-title {
  display: flex;
  align-items: center;
  text-align: center;
}
.bot-container .bot-pro-features-title span {
  margin: 0 10px;
}
.bot-container .bot-pro-features-title::before,
.bot-container .bot-pro-features-title::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #373749;
}
.bot-container .bot-pro-features-image-container {
  position: relative;
}
.bot-container .bot-pro-features-image {
  width: 100%;
  height: auto;
  display: block;
}
.bot-container .pro-features-overlay-text {
  position: absolute;
  top: 40%;
  font-size: min(32px, 16px + 1vw);
  font-family: "Roboto", sans-serif;
  width: 100%;
  font-weight: bold;
}
.bot-container .pro-features-overlay-subtext {
  position: absolute;
  top: 55%;
  font-size: min(12px, 6px + 1vw);
  font-family: "Roboto", sans-serif;
  width: 100%;
}
.bot-container .text-left {
  text-align: left;
  transform: translate(4%, -50%);
}
.bot-container .text-right {
  text-align: right;
  transform: translate(-8%, -50%);
}
.bot-container .down-arrow {
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}
.bot-container .bot-faqs-container {
  margin-top: 30px;
  padding-bottom: 20px;
}
.bot-container .bot-faqs-questions-container {
  margin: 20px 30px 30px 30px;
}
.bot-container .bot-faqs-question-container {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px #323232 solid;
}
.bot-container .bot-faqs-question-clickable {
  cursor: pointer;
}
.bot-container .bot-faqs-arrow {
  display: inline-block;
  float: right;
}
.bot-container .bot-faqs-question {
  display: inline-block;
}
.bot-container .bot-faqs-answer {
  margin-top: 10px;
  color: #999999;
}

