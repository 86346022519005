.setting-page {
  background: #1d1d26;
  border-right: 1px solid #373749;
  border-left: 1px solid #373749;
  height: 100%;
}
.setting-page .title {
  background-color: #272734;
  font-size: 24px;
}
.setting-page .profile-row {
  height: 125px;
  background-color: #272734;
  border-bottom: 2px solid #373749;
  position: relative;
}
.setting-page .profile-row_avatar {
  padding: 20px;
  text-align: center;
  position: absolute;
}
.setting-page .profile-row_avatar .avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #b3b3b3;
  font-size: 45px;
  font-weight: bold;
  padding: 12px;
  color: #23232f;
}
.setting-page .profile-row_information {
  position: absolute;
  cursor: pointer;
  width: calc(100% - 120px);
  padding: 37px 15px;
  left: 120px;
}
.setting-page .profile-row_information .information {
  float: left;
}
.setting-page .profile-row_information .information .display_name {
  font-size: 22px;
  font-weight: bold;
}
.setting-page .profile-row_information .information .account-label {
  color: #b3b3b3;
}
.setting-page .profile-row_information .more-arrow {
  float: right;
  font-size: 25px;
  padding-top: 10px;
}
.setting-page .profile-row_information:after {
  clear: both;
}
.setting-page .settings-list {
  background-color: #272734;
}
.setting-page .settings-list .settings-link {
  text-decoration: none;
}
.setting-page .settings-list .settings-link:hover, .setting-page .settings-list .settings-link:active, .setting-page .settings-list .settings-link:visited {
  text-decoration: none;
}
.setting-page .settings-list li {
  display: block;
  cursor: pointer;
  padding: 20px 25px;
  border-bottom: 1px solid #373749;
  font-size: 16px;
  line-height: 16px;
  position: relative;
}
.setting-page .settings-list li span {
  color: #999999;
  font-size: 14px;
}
.setting-page .settings-list li .more-arrow {
  position: absolute;
  font-size: 20px;
  color: #999999;
  top: 35%;
  right: 15px;
}
.setting-page .settings-list li:active, .setting-page .settings-list li:hover {
  background: rgba(255, 255, 255, 0.1);
}
.setting-page .setting-page_inner {
  margin-bottom: 15px;
}
.setting-page .logout-button {
  background-color: #272734;
  margin: 20px 10px 0px 10px;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #CC0000;
}

