.timeline-container {
  background: #272734;
  border-right: 1px solid #373749;
  border-left: 1px solid #373749;
  overflow: auto;
}

@media screen and (max-width: 738px) {
  .timeline-container {
    overflow-x: hidden;
    background: #1c1c21;
  }
}

