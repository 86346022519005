.explore-container {
  background: #272734;
}
.explore-container .categories--no-data {
  padding: 20px;
  font-size: 16px;
}
.explore-container .categories .title {
  font-size: 24px;
  border-bottom: 2px solid #373749;
}
.explore-container .categories .title.no-border {
  border-bottom: none;
}
.explore-container .categories .categories-list {
  background: #272734;
}
.explore-container .categories .categories-list .category-row {
  height: 320px;
  padding: 10px 20px 5px;
}

@media screen and (max-width: 738px) {
  .explore-container {
    background: #1c1c21;
  }
  .explore-container .categories .categories-list {
    background: #1c1c21;
    padding-bottom: 50px;
  }
}

