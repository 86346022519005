.welcome-page {
  background: #272734;
  border-right: 1px solid #373749;
  border-left: 1px solid #373749;
  height: 100%;
}
.welcome-page h1 {
  padding-top: 100px;
  text-align: center;
  font-size: 26px;
  font-weight: 700;
}
.welcome-page h2 {
  margin-top: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}
.welcome-page img {
  height: 35px;
  margin: 20px auto;
  display: block;
}
.welcome-page .sign-in_btn {
  text-transform: none;
  display: block;
  margin: 0 auto;
  height: 35px;
  line-height: 35px;
  background-color: #06F;
  border-radius: 8px;
}
.welcome-page .sign-in_btn:hover, .welcome-page .sign-in_btn:active {
  background-color: #1a75ff;
}

