.search {
  width: 300px;
  height: 45px;
  border-radius: 23px;
  background-color: #272734;
  color: #FFFFFF;
  transition: 0.3s ease;
  transition-property: width, background-color, color;
  z-index: 5;
  margin: 6px 20px 15px;
}
.search, .search_inner {
  position: relative;
}
.search_inner {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.search_icon, .search_input {
  display: inline-block;
  vertical-align: top;
}
.search_icon, .search_clear {
  text-align: center;
}
.search_icon {
  width: 30px;
  line-height: 30px;
  padding-left: 5px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
}
.search_icon i {
  line-height: inherit;
  font-size: 20px;
}
.search_input {
  width: calc(100% - 30px);
  height: 45px;
  line-height: 45px;
  padding: 0 25px 0 5px;
  border: none;
  background-color: transparent;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #FFFFFF;
  transition: color 0.3s ease;
}
.search_input::placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
}
.search_input:focus {
  outline: none;
}
.search_clear {
  position: absolute;
  top: calc(50% - 10px);
  right: 5px;
  width: 20px;
  font-size: 14px;
  cursor: pointer;
}
.search--disabled .search {
  pointer-events: none;
}
.search--disabled .search_inner {
  opacity: 0.3;
}

@media screen and (max-width: 1200px) {
  .search {
    width: 95%;
  }
}
@media screen and (max-width: 1008px) {
  .search {
    width: 95%;
    max-width: 180px;
  }
}
@media screen and (max-width: 738px) {
  .search {
    width: 80%;
  }
}

