.oauth-login-button {
  background-color: #1d1d26;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 0fr 1fr 5fr;
  place-items: center;
  text-transform: none;
  font-size: 16px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #0066ff;
  margin: 5px 0 5px 0;
  font-weight: bold;
}

