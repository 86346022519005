.account-setting-page {
  border-right: 1px solid #373749;
  border-left: 1px solid #373749;
  height: 100%;
  background: #1D1D26;
}
.account-setting-page_inner .form-message {
  color: #008000;
  padding: 0 25px;
}
.account-setting-page_inner .form-error {
  color: #CC0000;
  padding: 0 25px;
}
.account-setting-page_inner .update-form .row {
  width: 100%;
  display: block;
  padding: 15px 20px;
  border-top: 1px solid #373749;
  height: 50px;
}
.account-setting-page_inner .update-form .row:first-child {
  border-top: 0;
}
.account-setting-page_inner .update-form .row .field-error {
  color: #ff0066;
  margin-top: 5px;
}
.account-setting-page_inner .update-form label {
  display: inline-block;
  margin-bottom: 3px;
  color: #b3b3b3;
  font-size: 16px;
  padding: 0px 35px 10px 15px;
  line-height: 16px;
  width: 120px;
}
.account-setting-page_inner .update-form input {
  background: #1D1D26;
  color: #FFFFFF;
  height: 16x;
  font-size: 16px;
  line-height: 16px;
  width: 65%;
  border: none;
  padding: 0 10px;
}
.account-setting-page_inner .update-form .blueLink {
  position: relative;
  color: #0066ff;
  cursor: pointer;
  top: 12px;
  float: right;
  padding: 0 30px;
}
.account-setting-page_inner .update-form .icon {
  display: contents;
}
.account-setting-page_inner .update-form .icon .tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}
.account-setting-page_inner .update-form .icon .tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  right: 20px;
  top: -15px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
.account-setting-page_inner .update-form .icon .tooltip:hover .tooltiptext {
  visibility: visible;
}
.account-setting-page_inner .update-form button {
  float: right;
  width: 100px;
  height: 40px;
  border-radius: 8px;
  background: #373749;
  color: #FFFFFF;
  border: 1px solid #373749;
  cursor: pointer;
}
.account-setting-page_inner .update-form button:hover, .account-setting-page_inner .update-form button:active {
  box-shadow: 0 0 2px #999999;
}

