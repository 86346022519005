.account-setting-page {
  border-right: 1px solid #373749;
  border-left: 1px solid #373749;
  height: 100%;
  background: #1D1D26;
}
.account-setting-page .title {
  font-size: 20px;
}
.account-setting-page .title .back-button {
  font-size: 20px;
}
.account-setting-page .title .back-button .button_label {
  vertical-align: top;
}
.account-setting-page .title-label {
  display: inline-block;
  margin-left: 20%;
}
.account-setting-page .profile-row {
  height: 125px;
  background-color: #272734;
  border-bottom: 2px solid #373749;
  position: relative;
}
.account-setting-page .profile-row_avatar {
  padding: 20px;
  text-align: center;
  position: absolute;
}
.account-setting-page .profile-row_avatar .avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #b3b3b3;
  font-size: 45px;
  font-weight: bold;
  padding: 12px;
  color: #23232f;
}
.account-setting-page .profile-row_information {
  position: absolute;
  cursor: pointer;
  width: calc(100% - 120px);
  padding: 37px 15px;
  left: 120px;
}
.account-setting-page .profile-row_information .information {
  float: left;
}
.account-setting-page .profile-row_information .information .display_name {
  font-size: 22px;
  font-weight: bold;
}
.account-setting-page .profile-row_information .information .account-label {
  color: #b3b3b3;
}
.account-setting-page .profile-row_information .more-arrow {
  float: right;
  font-size: 25px;
  padding-top: 10px;
}
.account-setting-page .profile-row_information:after {
  clear: both;
}
.account-setting-page .settings-list {
  background-color: #272734;
}
.account-setting-page .settings-list .settings-link {
  text-decoration: none;
}
.account-setting-page .settings-list .settings-link:hover, .account-setting-page .settings-list .settings-link:active, .account-setting-page .settings-list .settings-link:visited {
  text-decoration: none;
}
.account-setting-page .settings-list li {
  display: block;
  cursor: pointer;
  padding: 20px 25px;
  border-bottom: 1px solid #373749;
  font-size: 16px;
  line-height: 16px;
  position: relative;
}
.account-setting-page .settings-list li span {
  color: #999999;
  font-size: 14px;
}
.account-setting-page .settings-list li .userdata {
  position: absolute;
  left: 250px;
  top: 21px;
  font-size: 14px;
  color: #999999;
}
.account-setting-page .settings-list li .blueLink {
  color: #0066ff;
  cursor: pointer;
}
.account-setting-page .settings-list li .more-arrow {
  position: absolute;
  font-size: 20px;
  color: #999999;
  top: 20px;
  right: 15px;
}
.account-setting-page .settings-list li:active, .account-setting-page .settings-list li:hover {
  background: rgba(255, 255, 255, 0.1);
}
.account-setting-page_inner .account-update-form .row {
  width: 100%;
  display: block;
  padding: 15px 20px;
  border-top: 1px solid #373749;
}
.account-setting-page_inner .account-update-form .row:first-child {
  border-top: 0;
}
.account-setting-page_inner .account-update-form label {
  margin-bottom: 3px;
  color: #b3b3b3;
}
.account-setting-page_inner .account-update-form input {
  width: 100%;
  background: #141415;
  color: #FFFFFF;
  height: 45px;
  line-height: 40px;
  border-radius: 5px;
  border: 2px solid #141415;
  padding: 0 10px;
}
.account-setting-page_inner .account-update-form button {
  float: right;
  width: 100px;
  height: 40px;
  border-radius: 8px;
  background: #373749;
  color: #FFFFFF;
  border: 1px solid #373749;
  cursor: pointer;
}
.account-setting-page_inner .account-update-form button:hover, .account-setting-page_inner .account-update-form button:active {
  box-shadow: 0 0 2px #999999;
}

