.field {
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
.field:not(:last-child) {
  margin-bottom: 8px;
}
.field_label {
  display: block;
  margin-bottom: 4px;
}
.field_input {
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 0 16px;
  border: 2px solid #141415;
  border-radius: 5px;
  background: #121212;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 32px;
}
.field_input::placeholder {
  font-family: "Roboto", sans-serif;
}
.field_input:focus {
  outline: none;
  border-color: #0066CC;
}
.field_input:read-only {
  color: rgba(255, 255, 255, 0.3);
}
.field_input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.field_input__error {
  border: 1px solid #ff0000;
}
.field textarea {
  min-height: 100px;
  max-height: 300px;
  resize: vertical;
}
.field--full {
  width: 100%;
}
.field--half {
  width: 50%;
}
.field--third {
  width: 33.333%;
}
.field .field-input-wrapper {
  position: relative;
}
.field .field-input-wrapper .password-visibility {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

