.privacy-page {
  background: #272734;
  border-right: 1px solid #373749;
  border-left: 1px solid #373749;
}
.privacy-page .doc-body-container {
  width: 100%;
  border-radius: 5px;
  min-height: 94vh;
  padding: 0 16px 25px;
}
.privacy-page .doc-body-container h2 {
  padding: 15px 0;
}
.privacy-page .doc-body-container .sub-title {
  font-size: 14px;
  padding: 0 0 20px;
}
.privacy-page .doc-body-container .body-section {
  font-size: 14px;
  line-height: 20px;
}
.privacy-page .doc-body-container .contact-email {
  font-size: 14px;
  display: block;
  margin: 25px 0 5px;
}

