#root {
  height: 100%;
}

.root-container {
  height: 100%;
  overflow: hidden;
}
.root-container_inner {
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
}
.root-container_inner .body {
  width: 50%;
  max-width: 700px;
  z-index: 10;
  background-color: #272734;
}

@media screen and (max-width: 1279px) {
  .root-container_inner {
    justify-content: normal;
    padding-left: 10px;
  }
}
@media screen and (max-width: 1008px) {
  .root-container_inner {
    padding: 0;
  }
}
@media screen and (max-width: 738px) {
  .root-container_inner {
    overflow-x: hidden;
  }
  .root-container_inner .body {
    flex-grow: 1;
    background: #1c1c21;
  }
}

