@import '../../resources/scss/variables';
@import '../../resources/scss/animations';

@mixin loadingMaskTheme($spinner-color, $overlay-color: black, $overlay-opacity: 0.25) {
  .loading-mask {
    &_spinner {
      background-color: $spinner-color;
    }

    &_overlay {
      background-color: $overlay-color;
      opacity: $overlay-opacity;
    }
  }
}

.loading-mask {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &, &_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z-index--mask;
  }

  &_overlay {
    background-color: transparent;
    transition: background-color $transition-duration--default $transition-timing-function--default;
  }

  &_spinner {
    position: relative;
    background-color: $white;
    z-index: $z-index--mask + 1;

    &, &:before, &:after {
      width: gux();
      height: gux(4);
      animation: loading-bars 1s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
    }

    & {
      animation-delay: -0.16s;
    }

    &:before, &:after {
      content: '';
      position: absolute;
      top: 0;
      background-color: inherit;
    }

    &:before {
      left: - gux(1.5);
      animation-delay: -0.32s;
    }

    &:after {
      left: gux(1.5);
    }
  }

  &--fixed {
    position: fixed;
  }

  &--light {
    @include loadingMaskTheme(rgba(black, 0.5));
  }

  &--dark {
    @include loadingMaskTheme(rgba(white, 0.5));
  }
}