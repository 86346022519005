.subscriptions {
  height: 210px;
}
.subscriptions--scroll {
  overflow-y: scroll;
}
.subscriptions::-webkit-scrollbar {
  width: 10px !important;
}
.subscriptions .subscriptions_list .subscription_item {
  margin-bottom: 5px;
  cursor: pointer;
}
.subscriptions .subscriptions_list .subscription_item--no-data {
  margin-left: 15px;
  color: #b3b3b3;
}
.subscriptions .subscriptions_list .subscription_item a {
  padding: 10px 0 6px 10px;
  display: block;
}
.subscriptions .subscriptions_list .subscription_item a:hover {
  background: #23232f;
  border-radius: 10px 0 0 10px;
}
.subscriptions .subscriptions_list .subscription_item a:hover, .subscriptions .subscriptions_list .subscription_item a:active, .subscriptions .subscriptions_list .subscription_item a:visited {
  text-decoration: none;
}
.subscriptions .subscriptions_list .subscription_item a img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;
  display: inline-block;
}
.subscriptions .subscriptions_list .subscription_item a label {
  text-decoration: none;
  font-size: 16px;
  vertical-align: top;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 170px;
  display: inline-block;
  cursor: pointer;
}

@media screen and (max-width: 1279px) {
  .subscriptions .subscriptions_list .subscription_item a label {
    max-width: 140px;
    width: 100%;
  }
}
@media screen and (max-width: 1008px) {
  .subscriptions .subscriptions_list .subscription_item a label {
    max-width: 100px;
    width: 100%;
  }
}
@media screen and (max-width: 734px) {
  .subscriptions .subscriptions_list .subscription_item--no-data {
    display: none;
  }
  .subscriptions .subscriptions_list .subscription_item a label {
    max-width: 100px;
    width: 100%;
    font-size: 14px;
  }
}
@media screen and (max-width: 738px) {
  .subscriptions {
    padding: 0;
    overflow-x: hidden;
  }
  .subscriptions .subscriptions_list .subscription_item--no-data {
    display: none;
  }
  .subscriptions .subscriptions_list .subscription_item .subscription_tooltip {
    display: none;
  }
  .subscriptions .subscriptions_list .subscription_item a:active, .subscriptions .subscriptions_list .subscription_item a:hover {
    border-radius: initial;
  }
  .subscriptions .subscriptions_list .subscription_item a img {
    width: 25px;
    height: 25px;
  }
}

