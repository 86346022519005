.search-container {
  width: 25%;
  max-width: 290px;
}
.search-container_top {
  position: fixed;
}
.search-container_ads {
  width: 300px;
  height: 330px;
  display: block;
  position: fixed;
  top: 65px;
  background-color: #272734;
  margin: 0 0 0 20px;
  border-radius: 15px;
}
.search-container_ads .ads-title {
  padding: 5px;
  color: rgba(128, 128, 128, 0.5);
}
.search-container_bottom {
  position: fixed;
  bottom: 0;
  width: 300px;
}

@media screen and (max-width: 1200px) {
  .search-container {
    max-width: 250px;
  }
  .search-container_bottom {
    width: 250px;
  }
  .search-container_ads {
    width: 235px;
  }
}
@media screen and (max-width: 1008px) {
  .search-container {
    max-width: 27%;
    width: 100%;
  }
  .search-container_bottom {
    max-width: 27%;
    width: 100%;
  }
  .search-container_bottom .footer .version {
    display: block;
    margin-top: 5px;
  }
  .search-container_ads {
    max-width: 23%;
    width: 100%;
  }
}
@media screen and (max-width: 738px) {
  .search-container {
    display: none;
  }
}

