.home-page {
  background: #272734;
  border-right: 1px solid #373749;
  border-left: 1px solid #373749;
}
.home-page_title {
  display: none;
}
.home-page_navigation {
  background: #272734;
}
.home-page_navigation .navigation_tabs {
  min-height: 55px;
  font-size: 14px;
  border-bottom: 2px solid #373749;
}
.home-page_navigation button.MuiTab-root {
  max-width: 100px;
  min-width: 100px;
  min-height: 50px;
  text-transform: none;
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.7;
}
.home-page_navigation button.MuiTab-root.Mui-selected {
  font-weight: bold;
  opacity: 1;
}
.home-page_navigation button.MuiTab-root:hover, .home-page_navigation button.MuiTab-root:active {
  opacity: 1;
}
.home-page_navigation span.MuiTabs-indicator {
  background: #FFFFFF;
  height: 3px;
}
.home-page_filter {
  display: grid;
  grid-template-columns: auto;
  grid-auto-flow: column;
  height: 60px;
  overflow-x: scroll;
  scrollbar-width: none;
  align-items: start;
  width: inherit;
}
.home-page_filter_thumbnail_container {
  width: 70px;
  height: 60px;
  text-align: center;
}
.home-page_filter_thumbnail_container.selected {
  background-color: #373749;
}
.home-page_filter_thumbnail_img {
  width: 60px;
  padding: 10px;
  border-radius: 50%;
}

@media screen and (max-width: 738px) {
  .home-page {
    background: #1c1c21;
  }
  .home-page_title {
    display: block;
    font-size: 18px;
    padding: 15px 10px 0 15px;
    height: 50px;
    border-bottom: 1px solid #3a3a41;
    background: #1c1c21;
  }
  .home-page_navigation {
    display: none;
  }
}

