@import '../../resources/scss/variables';

.navigation-container {
	width: 23%;
	max-width: 252px;
	flex-shrink: 0;

	&_inner {
	}

	&_top {
		width: gux(31.5);
		position: fixed;
		top: 0;

		&-logo {
			margin: 14px 20px;

			a {
				display: block;
				width: 30px;
			}

			img {
				width: 25px;
				height: 20px;
			}
		}

		&-menu {
			height: 100%;
			margin-top: 15px;
			border-bottom: 1px solid $black-marlin;
		}

		&-subscriptions {
			height: 100%;
			padding: 25px 0;
			border-bottom: 1px solid $black-marlin;

			&.sign-up {
				border-bottom: none;
				padding: 15px 15px 15px 0;

				.join-container {
					background: $dark-charade;
					border-radius: 15px;
					padding: gux(3) gux(2);
					color: $white;

					h3 {
						font-weight: bold;
						font-size: 18px;

						.brand {
							color: $blue-link;
						}
					}

					.message {
						padding: 10px 0;
					}

					.sign-up_button {
						text-transform: none;
						line-height: 35px;
						height: 35px;
						cursor: pointer;
						background-color: $blue11;
						border-radius: 8px;

						&:hover, &:active {
							background-color: $blue12;
						}
					}
				}
			}

			.section-header {
				font-size: 14px;
				text-transform: uppercase;
				margin-bottom: 10px;
				padding-left: 15px;
				color: $pink-swan;
			}
		}
	}

	&_bottom {
		width: 252px;
		height: 60px;
		position: fixed;
		bottom: 0;

		&-actions {

		}
	}

	.nav-item {
		line-height: 50px;
		height: 50px;
		color: $white;
		border-radius: 25px;
		padding: 0 30px 0 20px;
		margin: 0px 5px 10px 5px;
		width: 100%;
		text-align: left;


		&:active, &:hover {
			text-decoration: none;
			background: $shark;
		}

		&.user-nav-item {
			width: 96%;
			text-align: left;
			padding: 0 20px;
		}

		&.selected {
			background: $jaguar;
			font-weight: 700;
		}

		i, span.material-icons {
			font-size: 25px;
			line-height: 50px;
			text-decoration: none;

			&.secondary-icon {
				transform: rotate(270deg);
				font-size: 22px;
				float: right;
				line-height: 50px;
				pointer-events: none;
			}
		}

		.image-icon {
			width: 22px;
			height: 22px;
		}

		.button_label {
			font-size: 18px;
			padding-left: 12px;
			line-height: 52px;
			vertical-align: top;
			text-decoration: none;
		}
	}
}

@media screen and (max-width: 1279px) {
	.navigation-container {
		flex-shrink: initial;

		&_top,
		&_bottom {
			max-width: 230px;
			width: 100%
		}
	}
}

@media screen and (max-width: 1008px) {
	.navigation-container {
		flex-shrink: initial;
		position: relative;

		&_top,
		&_bottom {
			max-width: 174px;
			width: 100%
		}

		.nav-item {
			.button_label {
				font-size: 16px;
			}

			i {
				&.secondary-icon {
					margin-right: 0;
				}
			}
		}

		.join-container {
			margin: 0 5px;
		}
	}
}

@media screen and (min-height: 640px) {
	.navigation-container {
		&_inner {
			overflow-y: hidden !important;
		}
	}
}

@media screen and (max-width: 824px) {
	.navigation-container {
		&_inner {
			position: fixed;
			height: 100%;
			max-width: 25%;
		}

		&_top,
		&_bottom {
			position: relative;
			max-width: 100%;
		}

		&_top {
			&-subscriptions {
				margin-bottom: 25px;
			}
		}
	}
}

@media screen and (max-width: 738px) {
	.navigation-container {
		&_inner {
			max-width: 27%;
		}

		.nav-item {
			.button_label {
				font-size: 14px;
			}
		}

		.join-container {
			width: 85%;
		}
	}
}

@media screen and (max-width: 738px) {
	.navigation-container {
		flex-grow: 0;
		width: 50px;

		&_inner {
			background: $bastille;
			max-width: 50px;
			overflow: hidden;
			border-right: 1px solid #3a3a41;
		}

		.nav-item {
			border-radius: initial;
			padding: 0 10px;
			text-align: center;
			margin-bottom: 5px;

			.button_label {
				display: none;
			}

			i {
				&.secondary-icon {
					display: none;
				}
			}

			&.user-nav-item {
				padding: 0 10px;
			}
		}

		&_bottom {
			.discord-btn {
				display: none;
			}
		}

		&_top {
			&-logo {
				margin: 14px 12px 10px;
			}

			&-menu {
				margin-top: 0;
			}

			&-subscriptions {
				padding: 0;
				overflow-x: hidden;
				max-height: 175px;
				width: 75px;

				&.sign-up {
					padding: 0;

					.join-container {
						display: none;
					}
				}

				.subscription {
					.section-header {
						display: none;
					}
				}
			}
		}
	}
}

@media screen and (max-height: 750px) {
	.navigation-container {
		&_bottom {
			display: none;
		}
	}
}
