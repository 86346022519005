@import '../../resources/scss/variables';

#user-popover {
  left: 5px;

  div.profile-popover {
    max-width: 240px;
    width: 100%;
    background: $dark-charade;
    color: $white;
    padding: 10px 15px;
    border-radius: 15px;

    ul {

      li {
        display: block;
        cursor: pointer;
        padding: 5px 0;

        .popover-menu-item {
          padding-left: 0;

          i {
            font-size: 21px;
            line-height: 25px;
            text-decoration: none;
          }

          .button_label {
            font-size: 16px;
            padding-left: 12px;
            line-height: 30px;
            vertical-align: top;
            text-decoration: none;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 1279px) {
  #user-popover {
    div.profile-popover {
      max-width: 210px;
    }
  }
}

@media screen and (max-width: 1008px) {
  #user-popover {
    div.profile-popover {
      max-width: 140px;
    }
  }
}