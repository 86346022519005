@import '../../resources/scss/variables';

.home-page {
  background: $dark-charade;
	border-right: 1px solid $tuna;
	border-left: 1px solid $tuna;

  &_title {
    display: none;
  }

  &_navigation {
    background: $dark-charade;

    .navigation_tabs {
      min-height: 55px;
      font-size: 14px;
      border-bottom: 2px solid $tuna;
    }

    button.MuiTab-root {
      max-width: 100px;
      min-width: 100px;
      min-height: 50px;
			text-transform: none;
      font-size: 14px;
      color: $white;
      opacity: 0.7;

      &.Mui-selected {
          font-weight: bold;
          opacity: 1;
      }
      
			&:hover, &:active {
					opacity: 1;
			}
    }

    span.MuiTabs-indicator {
      background: $white;
      height: 3px;
    }
  }

  &_filter {
    display: grid;
    grid-template-columns: auto;
    grid-auto-flow: column;
    height: 60px;
    overflow-x: scroll;
    scrollbar-width: none;
    align-items: start;
    width: inherit;

    &_thumbnail_container {
      width: 70px;
      height: 60px;
      text-align: center;
      &.selected {
        background-color: $tuna;
      }
    }

    &_thumbnail_img {
      width: 60px;
      padding: 10px;
      border-radius: 50%;
    }
  }

}

@media screen and (max-width: 738px) {
  .home-page {
    background: $black-russian;

    &_title {
      display: block;
      font-size: 18px;
      padding: 15px 10px 0 15px;
      height: 50px;
      border-bottom: 1px solid $black-marlin;
      background: $black-russian;
    }

    &_navigation {
      display: none;
    }
  }

}