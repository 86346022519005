.button {
  display: inline-block;
  opacity: 1;
  border: none;
  border-radius: 3px;
  padding: 0 12px;
  height: 30px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s ease;
  cursor: pointer;
  background-color: #0066CC;
  color: #FFFFFF;
}
.button:hover, .button:focus {
  background: #025eba;
  color: #FFFFFF;
}
.button.button--loading:focus {
  background: #0066CC;
}
.button.button--loading:before {
  border-color: rgba(255, 255, 255, 0.3);
}
.button:disabled, .button.button--disabled {
  background: #0066CC;
  color: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
  pointer-events: all !important;
}
.button.button--not-available {
  background: #0066CC;
  color: rgba(255, 255, 255, 0.3) !important;
  pointer-events: all !important;
}
.button:focus {
  outline: none;
}
.button:hover .tooltip {
  display: block;
}
.button_icon, .button_label {
  vertical-align: middle;
  pointer-events: none;
}
.button_icon {
  line-height: 30px;
}
.button_icon + .button_label {
  margin-left: 8px;
}
.button--square, .button--circle {
  position: relative;
  padding: 0;
  width: 30px;
}
.button--square .tooltip, .button--circle .tooltip {
  display: none;
}
.button--circle {
  border-radius: 50%;
}
.button--tall {
  height: 40px;
}
.button--tall.button--square, .button--tall.button--circle {
  width: 40px;
}
.button--tall.button--square .button_icon, .button--tall.button--circle .button_icon {
  font-size: 24px;
  line-height: 40px;
}
.button--wide {
  width: 120px;
}
.button--cancel, .button--transparent {
  text-transform: capitalize;
  background-color: transparent;
  color: #E6E6E6;
}
.button--cancel:hover, .button--cancel:focus, .button--transparent:hover, .button--transparent:focus {
  background: transparent;
  color: #FFFFFF;
}
.button--cancel.button--loading:focus, .button--transparent.button--loading:focus {
  background: transparent;
}
.button--cancel.button--loading:before, .button--transparent.button--loading:before {
  border-color: rgba(230, 230, 230, 0.3);
}
.button--cancel:disabled, .button--cancel.button--disabled, .button--transparent:disabled, .button--transparent.button--disabled {
  background: transparent;
  color: rgba(230, 230, 230, 0.3);
  cursor: not-allowed;
  pointer-events: all !important;
}
.button--cancel.button--not-available, .button--transparent.button--not-available {
  background: transparent;
  color: rgba(230, 230, 230, 0.3) !important;
  pointer-events: all !important;
}
.button--invisible {
  opacity: 0;
  pointer-events: none;
}
.button--hidden {
  display: none;
}
.button:disabled, .button--disabled {
  pointer-events: none;
}
.button--block {
  display: block;
  text-align: left;
}
.button--bordered {
  border: 2px solid #0066CC;
  background: transparent;
}
.button--bordered:hover, .button--bordered:focus {
  background: rgba(0, 102, 204, 0.3);
  color: #FFFFFF;
}

