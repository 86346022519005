@import '../../../resources/scss/variables';

.topic-item {
  justify-content: space-between;

  &_details {
    position: relative;
    max-width: 700px;
    width: 100%;
    height: 135px;
    background-size: 100% 100%;
    background-blend-mode: color;
  }

  &_title {
    font-size: 26px;
    font-weight: bold;
    display: block;
    white-space: nowrap;
    overflow-x: clip;
  }

  &_subscription-count {
    color: $light-slate;
  }

  &_actions {
    position: absolute;
    top: 15px;
    left: 15px;
    max-width: 680px;
    width: 95%;

    .button-group-left {
      float: left;
      margin-right: 10px;

      .back-button {
        font-size: 26px;
        margin-top:13px;
      }
    }

    .title {
      float: left;
      width: 60%;
      word-wrap: break-word;
      display: flex;
      border-bottom: 0;

      &:after {
        clear: both;
      }

      img {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        margin-right: 25px;
      }
    }

    .button-group-right {
      float: right;

      .share-button {
        font-size: 26px;
        margin-right: 25px;
        top: 8px;
      }

      .subscribe-button {
        font-size: 14px;
        text-align: center;
        font-weight: bold;
        height: 40px;
        border-radius: 20px;
        text-transform: none;
        padding: 0 15px;
        background-color: $blue-link;
        margin-top: 6px;
      }

      .unsubscribe-button {
        color: $white;
        background-color: $tuna;
        min-width: 110px;
        font-size: 14px;
        text-align: center;
        font-weight: bold;
        height: 40px;
        border-radius: 20px;
        text-transform: none;
        padding: 0 15px;
        margin-top: 6px;

        &:before {
          content: "Subscribed";
        }

        &:hover, &:active {
          &:before {
            content: "Unsubscribe";
          }
        }
      }
    }

  }

  &_navigation {
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 100%;
    max-width: 700px;
    color: #fff;
    border-bottom: 1px solid $black-marlin;
    border-top: 1px solid $black-marlin;

    button.MuiTab-root {
      max-width: 100px;
      min-width: 100px;
			text-transform: none;
      font-size: 14px;
      color: $white;
      opacity: 0.7;

      &.Mui-selected {
          font-weight: bold;
          opacity: 1;
      }
      
			&:hover, &:active {
					opacity: 1;
			}
    }

    span.MuiTabs-indicator {
      background: $white;
      height: 3px;
      bottom: 2px;
    }
  }
}


@media screen and (max-width: 1279px) {
  .topic-item {
    &_actions {
      .title {
        width: 55%;
      }

      .button-group-right {
        .share-button {
          margin-right: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 1095px) {
  .topic-item {
    &_title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 330px;
    }

    &_actions {
      .button-group-right {
        margin-right: 10px;
      }

      img.topic-icon {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 930px) {
  .topic-item {
    &_title {
      font-size: 23px;
      width: 230px;
    }

    &_actions {
      .title {
        width: 45%;

        img {
          margin-right: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .topic-item {
    border-bottom: 1px solid $black-marlin;

    &_details {
      height: 180px;
    }

    &_actions {
      top: 10px;

      .button-group-left {
        margin-right: 0;

        .back-button {
          font-size: 21px;
        }
      }

      .title {
        width: 40%;
      }

      .button-group-right {
        clear: both;
        float: right;

        .share-button {
          margin-right: 5px;
        }
      }
    }

    &_title {
      font-size: 21px;
      top: 45px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 180px;
    }


    &_navigation {
      button.MuiTab-root {
        max-width: 90px;
        min-width: 60px;
        text-transform: none;
        font-size: 14px;
        line-height: 38px;

      }
    }
  }
}