@import '../../resources/scss/variables';

.trending-container {
  background: $dark-charade;
  border-right: 1px solid $tuna;
  border-left: 1px solid $tuna;

  &_navigation {
    background: $dark-charade;

    .navigation_tabs {
      min-height: 55px;
      font-size: 13px;
      border-bottom: 2px solid $tuna;
    }

    button.MuiTab-root {
      max-width: 96px;
      min-width: 90px;
      min-height: 50px;
			text-transform: none;
      font-size: 14px;
      color: $white;
      opacity: 0.7;

      &.Mui-selected {
          font-weight: bold;
          opacity: 1;
      }
      
			&:hover, &:active {
					opacity: 1;
			}
    }

    span.MuiTabs-indicator {
      background: $white;
      height: 3px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .trending-container {
    &_navigation {
      display: none;
    }
  }
}

@media screen and (max-width: 738px) {
  .trending-container {
    background: $black-russian;
  }
}