@import '../../resources/scss/variables';
@import '../../resources/scss/mixins';

.article {
  animation: fade-in $transition-duration--default $transition-timing-function--default;

  .title {
    font-size: $font-size--icon;
    border-bottom: 2px solid $tuna;

    &.no-border {
      border-bottom: none;
    }
  }

  &_list {
    background: $dark-charade;
    padding-bottom: gux(2);

    &_item {
      background-color: $dark-charade;
      padding: 15px;
      border-bottom: 1px solid $tuna;

      &--no-data {
        margin: gux(4);
        justify-content: center;
      }

      &:hover, &:active {
        background-color: $charade;
      }
    }
  }

  &_item {
    display: flex;

    &-content {
      position: relative;

      .content-body {
        .short-body {
          display: none;
          max-width: 640px;
          font-size: 14px;
        }

        .content-btn {
          position: absolute;
          font-size: 28px;
          right: 10px;
          top: -20px;

          &:after {
            clear: both;
          }
        }

        &.show {
          .short-body {
            display: block;
            padding: 15px 15px 0 0;

            .read-more {
              padding-left: 10px;
              color: $silver;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  &_thumbnail {
    width: 210px;
    height: 118px;
    border-radius: 10px;
    background-color: $jaguar;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
    cursor: pointer;
    position: relative;

    &_video_overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 210px;
      height: 118px;

      &_svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &_details {
    flex: 1;
    margin-left: gux(1.5);
  }

  &_title {
    margin: gux(0.25) 0 gux(1);
    font-size: 18px;
    color: $white;
    cursor: pointer;

    &:focus {
      text-decoration: underline;
      outline: none;
    }
  }

  &_action {
    color: $silver;
    cursor: pointer;
    font-size: 14px;

    &.share-btn,
    &.bookmark-btn {
      font-size: 16px;
      vertical-align: middle;
      margin-left: 5px;
      width: 25px;
      height: 25px;
    }

    &.bookmark-btn {
      font-size: 20px;
      margin-left: 3px;

      &.active {
        color: $blue;
      }
    }

    &.launch-btn {
      font-size: 20px;
      top: 3px;
    }

    &:hover, &:active {
      color: $white;
    }

    &--date {
      cursor: initial;
      margin-left: 8px;

      &:hover, &:active {
        color: $silver;
      }

      &:before {
        content: '';
        display: inline-block;
        width: gux(0.5);
        height: gux(0.5);
        margin-right: 6px;
        border-radius: $border-radius--circle;
        background-color: $silver;
        vertical-align: middle;
      }
    }

    &--topic {
      color: $silver;
      font-weight: 400;

      &:hover, &:active {
				color: $white;
      }
    }
  }
}

@media only screen and (max-width: 1008px) {
  .article {
    &_list {
      border: none;
      border-radius: 0;
    }

    &_item {
      display: block;
    }

    &_thumbnail {
      width: 100%;
      height: gux(20);
      background-size: cover;
      background-position: 0 50%;
    }

    &_details {
      margin: 10px 0 5px;
    }
  }
}

@media screen and (max-width: 738px) {
  .article {
    .title {
      &--section {
        font-size: 18px;
        padding: 15px 10px 0 15px;
        border-bottom: 1px solid $black-marlin;
        height: 50px;
        background: $black-russian;
      }
    }

    &_list {
      background: $black-russian;

      &_item {
        background-color: $black-russian;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .article {
    .title {
      &--section {
        display: none;
      }
    }
  }
}
