@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?6usuql");
  src: url("../fonts/icomoon.eot?6usuql#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?6usuql") format("truetype"), url("../fonts/icomoon.woff?6usuql") format("woff"), url("../fonts/icomoon.svg?6usuql#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play_arrow:before {
  content: "\e925";
}

.icon-launchopen_in_new:before {
  content: "\e924";
}

.icon-expand_less:before {
  content: "\e901";
}

.icon-expand_more:before {
  content: "\e916";
}

.icon-email:before {
  content: "\e921";
}

.icon-vpn_key:before {
  content: "\e922";
}

.icon-edit:before {
  content: "\e923";
}

.icon-warning:before {
  content: "\e920";
}

.icon-close:before {
  content: "\e91f";
}

.icon-settings:before {
  content: "\e91d";
}

.icon-exit_to_app:before {
  content: "\e91e";
}

.icon-home:before {
  content: "\e912";
}

.icon-check:before {
  content: "\e900";
}

.icon-keyboard_control:before {
  content: "\e902";
}

.icon-more_vert:before {
  content: "\e903";
}

.icon-search:before {
  content: "\e904";
}

.icon-share:before {
  content: "\e905";
}

.icon-feedback:before {
  content: "\e906";
}

.icon-explore:before {
  content: "\e907";
}

.icon-bookmark:before {
  content: "\e908";
}

.icon-bookmark_outlineturne:before {
  content: "\e909";
}

.icon-favorite:before {
  content: "\e90a";
}

.icon-favorite_outline:before {
  content: "\e90b";
}

.icon-bookmarks:before {
  content: "\e90c";
}

.icon-avatar:before {
  content: "\e90d";
}

.icon-trending:before {
  content: "\e90e";
}

.icon-apps:before {
  content: "\e914";
}

.icon-menu:before {
  content: "\e915";
}

.icon-arrow_back:before {
  content: "\e917";
}

.icon-arrow_forward:before {
  content: "\e918";
}

.icon-thumb_down:before {
  content: "\e919";
}

.icon-thumb_up:before {
  content: "\e91a";
}

.icon-close_outline:before {
  content: "\e91b";
}

.icon-info_outline:before {
  content: "\e91c";
}

.icon-arrow_back_ios:before {
  content: "\e926";
}

.icon-arrow_forward_ios:before {
  content: "\e927";
}

.icon-instagram:before {
  content: "\e90f";
}

.icon-discord:before {
  content: "\e910";
}

.icon-facebook:before {
  content: "\e911";
}

.icon-twitter-with-circle:before {
  content: "\e913";
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #23232f;
  background-color: #23232f;
}

::-webkit-scrollbar-thumb {
  background-color: #3a3a41;
}

html,
body {
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  background-color: #121217;
  color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

body {
  margin: 0;
}

h1, h2, h3, h4 {
  margin: 0;
  font-weight: 400;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}
a:focus {
  outline: none;
  text-decoration: underline;
}

input, textarea, button {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

input,
textarea {
  border: none;
  outline: none;
}

.link {
  color: #0066CC;
  transition: color 0.3s ease;
  cursor: pointer;
}
.link:hover {
  color: #004d99;
}

.overflow-hidden {
  overflow: hidden;
}

.hidden {
  display: none;
}

::-ms-clear {
  display: none;
}

.large-icon {
  width: 28px;
  height: 28px;
  font-size: 28px;
  line-height: 28px;
}

.medium-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
}

.small-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
  line-height: 18px;
}

@media screen and (max-width: 736px) {
  body {
    height: 100vh;
  }
}
.MuiSnackbarContent-root {
  background-color: #0066CC !important;
}

.MuiSnackbarContent-message {
  font-size: 14px;
}

