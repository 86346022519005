.title {
  font-family: "Roboto", sans-serif;
  border-bottom: 2px solid #373749;
}
.title--page {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 16px 4px;
}
.title--topic {
  font-size: 24px;
  font-weight: 600;
}
.title--section {
  font-size: 24px;
  font-weight: 600;
  padding: 11px;
}

