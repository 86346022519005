@import '../../resources/scss/variables';

.footer {
  margin: 0 10px 20px;
  text-align: center;
  color: $shaded-text;

  &_item {
    margin: 5px 0;
    font-size: 13px;

    &.footer_social-icons {
      margin-bottom: 15px;
      font-size: 12px;

      .social-icons {
        color: $shaded-text;

				.img-icon {
					width: 25px;
					margin: 0 10px;
					top: 2px;
					position: relative;
					opacity: 0.6;

					&:hover, &:active {
						opacity: 1;
					}
				}

				&:hover, &:active {
					color: $white;
				}
      }
    }

    .tattle-link.privacy-policy,
    .tattle-link.terms-of-use,
		.tattle-link.discord-bot {
      text-decoration: underline;
      color: $silver;

			&:hover, &:active {
				color: $white;
			}
    }

		.tattle-link.privacy-policy,
		.tattle-link.terms-of-use {
      margin: auto;
      width: 100px;
      display: inline-block;
    }

    i {
      font-size: 25px;
      margin: 0 10px;
    }
  }

  .version {
    color: $silver;
  }
}

@media screen and (max-width: 1008px) {
  .footer {
    &_item {
      .tattle-link.privacy-policy,
      .tattle-link.terms-of-use {
        display: block;
        padding-right: 0;
        margin-top: 5px;
      }
    }
  }
}

@media screen and (max-width: 734px) {
  .footer {
    &_copy {
      font-size: 12px;
    }
  }
}