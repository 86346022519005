@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slide-in--from-bottom {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes loading-bars {
  0%, 80%, 100% {
    box-shadow: 0 0;
    height: 32px;
  }
  40% {
    box-shadow: -16px;
    height: 40px;
  }
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fade-in 0.3s ease;
  animation-fill-mode: both;
  z-index: 55;
}
.modal_mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(19, 19, 19, 0.85);
  z-index: 56;
}
.modal_container {
  position: relative;
  width: 500px;
  border-radius: 8px;
  background-color: #272734;
  z-index: 57;
  display: grid;
}
.modal_container:focus {
  outline: none;
}
.modal_header {
  display: grid;
  grid-template-columns: 9fr 1fr;
  align-items: center;
  justify-content: center;
  height: 75px;
}
.modal_title {
  font-size: 16px;
  font-weight: bold;
  margin-left: 15px;
}
.modal_exit {
  margin: 24px;
  font-size: 24px;
  color: #b3b3b3;
}
.modal--hiding {
  animation-name: fade-out;
}
.modal--slide-up .modal_container {
  animation: slide-in--from-bottom 0.2s ease;
}

@media screen and (max-width: 1008px) {
  .modal_container {
    overflow-y: scroll;
    max-height: 82vh;
  }
}
@media screen and (min-height: 738px) {
  .modal_container {
    overflow-y: hidden;
    max-height: initial;
  }
}
@media screen and (max-width: 600px) {
  .modal_container {
    width: 90%;
  }
}

