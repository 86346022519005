@import '../../../resources/scss/variables';

.account-setting-page {
  border-right: 1px solid $tuna;
  border-left: 1px solid $tuna;
  height: 100%;
  background: #1D1D26;


  .title {
    font-size: 20px;

    .back-button {
      font-size: 20px;

      .button_label {
        vertical-align: top;
      }
    }

    &-label {
      display: inline-block;
      margin-left: 20%;
    }
  }

  .profile-row {
		height: 125px;
		background-color: #272734;
		border-bottom: 2px solid $tuna;
		position: relative;

		&_avatar {
			padding: 20px;
			text-align: center;
			position: absolute;

			.avatar {
				width: 80px;
				height: 80px;
				border-radius: 50%;
				background: $pink-swan;
				font-size: 45px;
				font-weight: bold;
				padding: 12px;
				color: $jaguar;
			}
		}

		&_information {
			position: absolute;
			cursor: pointer;
			width: calc(100% - 120px);
			padding: 37px 15px;
			left: 120px;

			.information {
				float: left;

				.display_name {
					font-size: 22px;
					font-weight: bold;
				}

				.account-label {
					color: $pink-swan;
				}
			}

			.more-arrow {
				float: right;
				font-size: 25px;
				padding-top: 10px;
			}

			&:after {
				clear: both;
			}
		}
	}

  .settings-list {
		background-color: #272734;
		.settings-link {
			text-decoration: none;
			&:hover, &:active, &:visited {
				text-decoration: none;
			}
		}

		li {
			display: block;
			cursor: pointer;
			padding: 20px 25px;
			border-bottom: 1px solid $tuna;
			font-size: 16px;
			line-height: 16px;
			position: relative;

			span {
				color: $silver;
				font-size: 14px;
			}

      .userdata {
        position: absolute;
        left: 250px;
        top: 21px;
        font-size: 14px;
        color: $silver;
      }

      .blueLink{
        color: $blue-link;
		cursor: pointer;
      }

			.more-arrow {
				position: absolute;
				font-size: 20px;
				color: $silver;
				top: 20px;
				right: 15px;
			}

			&:active, &:hover {
				background: rgba(255,255,255,0.1);
			}
		}
	}
  

  &_inner {
    .account-update-form {
      .row {
        width: 100%;
        display: block;
        padding: 15px 20px;
        border-top: 1px solid $tuna;

        &:first-child {
          border-top: 0;
        }
      }

      label {
        margin-bottom: 3px;
        color: $pink-swan;
      }

      input {
        width: 100%;
        background: $woodsmoke;
        color: $white;
        height: 45px;
        line-height: 40px;
        border-radius: 5px;
        border: 2px solid $woodsmoke;
        padding: 0 10px;
      }

      button {
        float: right;
        width: 100px;
        height: 40px;
        border-radius: 8px;
        background: $tuna;
        color: $white;
        border: 1px solid $tuna;
        cursor: pointer;

        &:hover, &:active {
          box-shadow: 0 0 2px $silver;
        }
      }
    }
  }
}