@import '../../resources/scss/variables';

.setting-page {
	background: $shark;
	border-right: 1px solid $tuna;
	border-left: 1px solid $tuna;
	height: 100%;

	.title {
		background-color: $dark-charade;
		font-size: 24px;
	}

	.profile-row {
		height: 125px;
		background-color: $dark-charade;
		border-bottom: 2px solid $tuna;
		position: relative;

		&_avatar {
			padding: 20px;
			text-align: center;
			position: absolute;

			.avatar {
				width: 80px;
				height: 80px;
				border-radius: 50%;
				background: $pink-swan;
				font-size: 45px;
				font-weight: bold;
				padding: 12px;
				color: $jaguar;
			}
		}

		&_information {
			position: absolute;
			cursor: pointer;
			width: calc(100% - 120px);
			padding: 37px 15px;
			left: 120px;

			.information {
				float: left;

				.display_name {
					font-size: 22px;
					font-weight: bold;
				}

				.account-label {
					color: $pink-swan;
				}
			}

			.more-arrow {
				float: right;
				font-size: 25px;
				padding-top: 10px;
			}

			&:after {
				clear: both;
			}
		}
	}

	.settings-list {
		background-color: $dark-charade;
		.settings-link {
			text-decoration: none;
			&:hover, &:active, &:visited {
				text-decoration: none;
			}
		}

		li {
			display: block;
			cursor: pointer;
			padding: 20px 25px;
			border-bottom: 1px solid $tuna;
			font-size: 16px;
			line-height: 16px;
			position: relative;

			span {
				color: $silver;
				font-size: 14px;
			}

			.more-arrow {
				position: absolute;
				font-size: 20px;
				color: $silver;
				top: 35%;
				right: 15px;
			}

			&:active, &:hover {
				background: rgba(255,255,255,0.1);
			}
		}
	}

	.setting-page_inner {
		margin-bottom: 15px;
	}

	.logout-button {
		background-color: $dark-charade;

		margin: 20px 10px 0px 10px;
		border-radius: 10px;
		height: 50px;
		
		display: flex;
		align-items: center;
		justify-content: center;

		color: $red;
	}
}