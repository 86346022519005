.membership-setting-page {
  background: #272734;
  border-right: 1px solid #373749;
  border-left: 1px solid #373749;
  height: 100%;
}
.membership-setting-page .title {
  font-size: 20px;
}
.membership-setting-page .title .back-button {
  font-size: 20px;
}
.membership-setting-page .title .back-button .button_label {
  vertical-align: top;
}
.membership-setting-page .title-label {
  display: inline-block;
  margin-left: 22%;
}
.membership-setting-page_inner .membership-type_section .header,
.membership-setting-page_inner .active-servers_section .header {
  display: flex;
  justify-content: space-between;
  margin: 0 15px;
  padding: 15px 0;
  border-bottom: 1px solid #373749;
}
.membership-setting-page_inner .membership-type_section .header .header-section-label,
.membership-setting-page_inner .active-servers_section .header .header-section-label {
  font-weight: bold;
}
.membership-setting-page_inner .membership-type_section .header .call-to-action,
.membership-setting-page_inner .membership-type_section .header .membership-bill-date,
.membership-setting-page_inner .active-servers_section .header .call-to-action,
.membership-setting-page_inner .active-servers_section .header .membership-bill-date {
  color: #999999;
}
.membership-setting-page_inner .membership-type_section .header .call-to-action.paid,
.membership-setting-page_inner .active-servers_section .header .call-to-action.paid {
  padding-top: 8px;
}
.membership-setting-page_inner .membership-type_section .details,
.membership-setting-page_inner .active-servers_section .details {
  padding: 15px 15px;
  border-bottom: 1px solid #373749;
  min-height: 50px;
}
.membership-setting-page_inner .membership-type_section .details_billing,
.membership-setting-page_inner .active-servers_section .details_billing {
  margin-top: 15px;
}
.membership-setting-page_inner .membership-type_section .details_plan, .membership-setting-page_inner .membership-type_section .details_billing, .membership-setting-page_inner .membership-type_section .details_server,
.membership-setting-page_inner .active-servers_section .details_plan,
.membership-setting-page_inner .active-servers_section .details_billing,
.membership-setting-page_inner .active-servers_section .details_server {
  color: #999999;
}
.membership-setting-page_inner .membership-type_section .details_plan-label, .membership-setting-page_inner .membership-type_section .details_billing-label, .membership-setting-page_inner .membership-type_section .details_server-label,
.membership-setting-page_inner .active-servers_section .details_plan-label,
.membership-setting-page_inner .active-servers_section .details_billing-label,
.membership-setting-page_inner .active-servers_section .details_server-label {
  font-weight: bold;
  color: #FFFFFF;
}
.membership-setting-page_inner .membership-type_section .details_plan-type_label, .membership-setting-page_inner .membership-type_section .details_billing-type_label, .membership-setting-page_inner .membership-type_section .details_server-type_label,
.membership-setting-page_inner .active-servers_section .details_plan-type_label,
.membership-setting-page_inner .active-servers_section .details_billing-type_label,
.membership-setting-page_inner .active-servers_section .details_server-type_label {
  color: #999999;
}
.membership-setting-page_inner .membership-type_section .details_plan-action, .membership-setting-page_inner .membership-type_section .details_billing-action, .membership-setting-page_inner .membership-type_section .details_server-action,
.membership-setting-page_inner .active-servers_section .details_plan-action,
.membership-setting-page_inner .active-servers_section .details_billing-action,
.membership-setting-page_inner .active-servers_section .details_server-action {
  color: #0066ff;
  cursor: pointer;
}
.membership-setting-page_inner .membership-type_section .details_plan-action.right, .membership-setting-page_inner .membership-type_section .details_billing-action.right, .membership-setting-page_inner .membership-type_section .details_server-action.right,
.membership-setting-page_inner .active-servers_section .details_plan-action.right,
.membership-setting-page_inner .active-servers_section .details_billing-action.right,
.membership-setting-page_inner .active-servers_section .details_server-action.right {
  float: right;
}
.membership-setting-page_inner .membership-type_section .details_plan,
.membership-setting-page_inner .active-servers_section .details_plan {
  display: flex;
  justify-content: space-between;
}
.membership-setting-page_inner .details_billing {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.membership-setting-page_inner .active-servers_section {
  margin-top: 20px;
  border-top: 1px solid #373749;
}

