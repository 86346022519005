@import '../../resources/scss/variables';

.category-topics-list {
	.category-name {
		font-weight: bold;
		font-size: 24px;
		position: relative;
		cursor: pointer;

		.explore-all-label {
			display: none;
			opacity: 0;
			font-size: 14px;
			padding-left: 10px;

			.explore-all-icon {
				font-size: 14px;
				padding-left: 2px;
			}
		}

		.show-more-icon {
			position: absolute;
			top: 5px;
		}

		&:hover, &:active {
			.explore-all-label {
				transition: opacity 2s ease-out;
				opacity: 1;
				display: initial;
			}

			.show-more-icon {
				display: none;
			}
		}
	}

	.topic-list {
		display: flex;
		flex-direction: row;
		overflow-x: scroll;
		overflow-y: hidden;
		padding: 10px 0;
		height: 260px;

		&::-webkit-scrollbar {
			height: 10px;
			border-radius: 8px;
		}

		&::-webkit-scrollbar-track {
			background-color: #45454d !important;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #16161e !important;
			border-radius: 5px;
			width: 30px !important;
		}


		.topic_item {
			display: inline-block;

			.topic_thumbnail {
				display:block;
				width: 140px;
				margin: 0 10px 5px 0;

				&:last-child {
					margin-right: 0;
				}

				&:hover, &:active {
					-webkit-filter: brightness(120%);
					filter: brightness(120%);
				}
			}

			.topic_tooltip {
				font-weight: 700;
				font-size: 14px;
			}
		}

	}

}
