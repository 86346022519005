.trending-container {
  background: #272734;
  border-right: 1px solid #373749;
  border-left: 1px solid #373749;
}
.trending-container_navigation {
  background: #272734;
}
.trending-container_navigation .navigation_tabs {
  min-height: 55px;
  font-size: 13px;
  border-bottom: 2px solid #373749;
}
.trending-container_navigation button.MuiTab-root {
  max-width: 96px;
  min-width: 90px;
  min-height: 50px;
  text-transform: none;
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.7;
}
.trending-container_navigation button.MuiTab-root.Mui-selected {
  font-weight: bold;
  opacity: 1;
}
.trending-container_navigation button.MuiTab-root:hover, .trending-container_navigation button.MuiTab-root:active {
  opacity: 1;
}
.trending-container_navigation span.MuiTabs-indicator {
  background: #FFFFFF;
  height: 3px;
}

@media screen and (max-width: 1280px) {
  .trending-container_navigation {
    display: none;
  }
}
@media screen and (max-width: 738px) {
  .trending-container {
    background: #1c1c21;
  }
}

