@import '../../resources/scss/variables';

#root {
  height: 100%;
}

.root-container {
  height: 100%;
  overflow: hidden;

  &_inner {
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;

    .body {
      width: 50%;
      max-width: 700px;
      z-index: 10;
      background-color: $dark-charade;
    }
  }
}


@media screen and (max-width: 1279px) {
  .root-container {
    &_inner {
      justify-content: normal;
      padding-left: 10px;
    }
  }
}

@media screen and (max-width: 1008px) {
  .root-container {
    &_inner {
      padding: 0;
    }
  }
}

@media screen and (max-width: 738px) {
  .root-container {
    &_inner {
      overflow-x: hidden;
      .body {
        flex-grow: 1;
				background: $black-russian;
      }
    }
  }
}