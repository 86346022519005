.explore-container {
  background: #272734;
  border-right: 1px solid #373749;
  border-left: 1px solid #373749;
}
.explore-container .btn-back {
  height: 45px;
  display: block;
  line-height: 40px;
  font-size: 18px;
  padding-left: 15px;
  width: 85px;
}

@media screen and (max-width: 738px) {
  .explore-container {
    background: #1c1c21;
  }
}

