.login-divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: #999999;
  font-size: 12px;
}
.login-divider span {
  margin: 15px;
}

.login-divider::before,
.login-divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #373749;
}

.login-modal_inner {
  display: grid;
  justify-content: left;
  grid-template-rows: 1fr auto;
  padding-left: 75px;
  padding-right: 75px;
  margin-bottom: 40px;
  text-align: center;
}
.login-modal_inner .error_message {
  color: #CC0000;
  margin: 10px 0 10px;
}
.login-modal_title {
  font-size: 20px;
  font-weight: 600;
  justify-self: left;
}
.login-modal_disclaimer {
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: left;
}
.login-modal_disclaimer .tattle-link {
  color: #349cff;
}
.login-modal_form {
  width: 100%;
}
.login-modal_form .button {
  width: 100%;
  margin-top: 8px;
  height: 40px;
  border-radius: 10px;
  text-transform: none;
  font-weight: bold;
}
.login-modal_form .field_input {
  background: #141415;
  height: 50px;
  border-radius: 10px;
  line-height: 40px;
}
.login-modal_form .password-requirements {
  font-size: 12px;
  color: #999999;
  text-align: left;
  margin-bottom: 15px;
}
.login-modal_message {
  margin-top: 24px;
  font-size: 14px;
  text-align: center;
}
.login-modal_message .link {
  font-weight: bold;
  color: #349cff;
}
.login-modal_response {
  margin: 16px 0;
  text-align: center;
  font-style: italic;
}
.login-modal_register-terms {
  font-size: 12px;
  margin: 24px 0 16px;
}
.login-modal_register-terms .link {
  display: block;
  font-size: 14px;
}

@media screen and (max-width: 738px) {
  .login-modal_message .link {
    display: block;
  }
}
@media screen and (max-width: 500px) {
  .login-modal_inner {
    padding: 10px;
  }
}

