.jump-to-top-link {
  position: sticky;
  bottom: 5px;
  float: right;
  opacity: 75%;
  width: 150px;
  height: 50px;
  background-color: #121217;
  border-radius: 20px;
  border: 1px solid #1d1d26;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
}

.jump-to-top-link:hover {
  background-color: #323232;
  cursor: pointer;
  opacity: 85%;
}

.jump-to-top-arrow {
  margin-right: 10px;
}

html {
  scroll-behavior: smooth !important;
}

@media screen and (max-width: 738px) {
  .jump-to-top-text {
    display: none;
  }
  .jump-to-top-link {
    margin-right: 5px;
    width: 40px;
    height: 40px;
    justify-content: center;
    opacity: 85%;
  }
  .jump-to-top-arrow {
    margin-right: 0 !important;
  }
}

