.category-topics-list .category-name {
  font-weight: bold;
  font-size: 24px;
  position: relative;
  cursor: pointer;
}
.category-topics-list .category-name .explore-all-label {
  display: none;
  opacity: 0;
  font-size: 14px;
  padding-left: 10px;
}
.category-topics-list .category-name .explore-all-label .explore-all-icon {
  font-size: 14px;
  padding-left: 2px;
}
.category-topics-list .category-name .show-more-icon {
  position: absolute;
  top: 5px;
}
.category-topics-list .category-name:hover .explore-all-label, .category-topics-list .category-name:active .explore-all-label {
  transition: opacity 2s ease-out;
  opacity: 1;
  display: initial;
}
.category-topics-list .category-name:hover .show-more-icon, .category-topics-list .category-name:active .show-more-icon {
  display: none;
}
.category-topics-list .topic-list {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 10px 0;
  height: 260px;
}
.category-topics-list .topic-list::-webkit-scrollbar {
  height: 10px;
  border-radius: 8px;
}
.category-topics-list .topic-list::-webkit-scrollbar-track {
  background-color: #45454d !important;
}
.category-topics-list .topic-list::-webkit-scrollbar-thumb {
  background-color: #16161e !important;
  border-radius: 5px;
  width: 30px !important;
}
.category-topics-list .topic-list .topic_item {
  display: inline-block;
}
.category-topics-list .topic-list .topic_item .topic_thumbnail {
  display: block;
  width: 140px;
  margin: 0 10px 5px 0;
}
.category-topics-list .topic-list .topic_item .topic_thumbnail:last-child {
  margin-right: 0;
}
.category-topics-list .topic-list .topic_item .topic_thumbnail:hover, .category-topics-list .topic-list .topic_item .topic_thumbnail:active {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
}
.category-topics-list .topic-list .topic_item .topic_tooltip {
  font-weight: 700;
  font-size: 14px;
}

