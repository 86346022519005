.article-detail {
  background: #272734;
  height: 100%;
}
.article-detail_header {
  display: flex;
  justify-content: space-between;
  height: 50px;
  line-height: 50px;
  background: #272734;
}
.article-detail_header .header_back-button {
  color: #FFFFFF;
  font-size: 25px;
  width: 40px;
  padding: 5px;
  margin: 7px;
}
.article-detail_header .header_action3 {
  text-align: center;
  line-height: 60px;
}
.article-detail_header .header_action3 a, .article-detail_header .header_action3 button {
  font-size: 23px;
  margin-right: 10px;
}
.article-detail_header .header_action3 a:hover, .article-detail_header .header_action3 a:active, .article-detail_header .header_action3 a:visited, .article-detail_header .header_action3 button:hover, .article-detail_header .header_action3 button:active, .article-detail_header .header_action3 button:visited {
  text-decoration: none;
}
.article-detail .article-frame {
  height: calc(100% - 55px);
  background-color: #FFFFFF;
}

@media screen and (max-width: 738px) {
  .article-detail {
    min-height: 600px;
    background: #1c1c21;
  }
  .article-detail_header {
    background: #1c1c21;
  }
  .article-detail_header .header_feed-title {
    max-width: 90px;
    overflow: hidden;
    visibility: hidden;
  }
}

