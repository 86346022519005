@import '../../resources/scss/variables';

.bot-container {
	background-size: contain;
	width: 100%;
	position: relative;
	background-color: #121217;
	border-right: 1px solid $tuna;
	border-left: 1px solid $tuna;

	/* Header Styles */
	.top-image-container {
		position: relative;
	}
	
	.bot-library-image {
		width: 100%;
		opacity: 50%;
		height: auto;
		display: block;
	}
	
	.header-overlay-text {
		position: absolute;
		/* margin: auto; */
		top: 60%;
		text-align: center;
		transform: translate(0, -50%);
		font-size: min(48px, calc(24px + 2vw));
		font-family: 'Roboto', sans-serif; 
		width: 100%;
		font-weight: bold;
	}

	/* Subscription Options Styles */
	.bot-subscriptions-container {
		display: grid;
		grid-template-columns: 1fr 0.1fr 1fr;
	}

	.bot-subscription-container {
		display: inline-block;
	}

	.free-container {
		margin-left: 10%;
	}

	.pro-container {
		margin-right: 10%;
	}

	.bot-subscription-pro-img {
		width: 20px;
		vertical-align: top;
		margin-top: 20px;
	}

	.bot-subscription-spacer {
		margin: 0;
	}
	
	.bot-subscription-title {
		background-color: $tuna;
		text-align: center;
		height: 80px;
		border-radius: 10px 10px 0 0;
		font-size: min(28px, calc(16px + 1vw));
		font-family: 'Roboto', sans-serif;
		line-height: 80px;
	}

	.bot-subscription-description {
		height: 180px;
		background: #1D1D26;
		padding: 15px;
		font-size: min(14px, calc(8px + 1vw));
	}

	.bot-subscription-features {
		margin-top: 10px;
	}

	.bot-subscription-features li {
		padding-bottom: 6px;
	}

	.bot-subscription-features {
		list-style-type: disc;
		padding-left: 20px;
	}

	.bot-subscription-select {
		text-align: center;
		background-color: #1D1D26;
		border-top: 1px #323232 solid;
		border-radius: 0 0 10px 10px;
		height: 120px;
		display: grid;
		grid-template-rows: 3fr 1fr 1fr;
		place-items: center;
	}

	.bot-subscription-button {
		text-transform: none;
		line-height: 35px;
		height: 35px;
		cursor: pointer;
		border-radius: 4px;
		width: 80%;
		font-weight: bold;
		font-size: min(12px, calc(6px + 1vw));
	}

	.free-button {
		background-color: $tuna;
		&:hover, &:active {
			background-color: #474759;
		}
	}

	.pro-button {
		background-color: #066AFF;
		&:hover, &:active {
			background-color: #167AFF;
		}
	}

	/* Pro Features Styles */

	.bot-pro-features-container {
		margin-top: 30px;
	}

	.bot-pro-features-title {
		display: flex;
		align-items: center;
		text-align: center;
		
		span {
			margin: 0 10px;
		}
	}

	.bot-pro-features-title::before,
	.bot-pro-features-title::after {
		content: '';
		flex: 1;
		border-bottom: 1px solid $tuna;
	}

	.bot-pro-features-image-container {
		position: relative;
	}
	
	.bot-pro-features-image {
		width: 100%;
		height: auto;
		display: block;
	}

	.pro-features-overlay-text {
		position: absolute;
		top: 40%;
		font-size: min(32px, calc(16px + 1vw));
		font-family: 'Roboto', sans-serif; 
		width: 100%;
		font-weight: bold;
	}

	.pro-features-overlay-subtext {
		position: absolute;
		top: 55%;
		font-size: min(12px, calc(6px + 1vw));
		font-family: 'Roboto', sans-serif; 
		width: 100%;
	}
	.text-left {
		text-align: left;
		transform: translate(4%, -50%);
	}

	.text-right {
		text-align: right;
		transform: translate(-8%, -50%);
	}

	.down-arrow {
		width: 100%;
		text-align: center;
		margin-bottom: 30px;
	}

	/* Bot FAQs Styles */
	.bot-faqs-container {
		margin-top: 30px;
		padding-bottom: 20px;
	}

	.bot-faqs-questions-container {
		margin: 20px 30px 30px 30px;
	}

	.bot-faqs-question-container {
		padding-bottom: 10px;
		padding-top: 10px;
		border-bottom: 1px #323232 solid;
	}

	.bot-faqs-question-clickable {
		cursor: pointer;
	}

	.bot-faqs-arrow {
		display: inline-block;
		float: right;
	}

	.bot-faqs-question {
		display: inline-block;
	}

	.bot-faqs-answer {
		margin-top: 10px;
		color: $silver;
	}

}
