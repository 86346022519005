@import '../../resources/scss/variables';

.timeline-container {
  background: $dark-charade;
	border-right: 1px solid $tuna;
	border-left: 1px solid $tuna;
  overflow: auto;
}

@media screen and (max-width: 738px) {
  .timeline-container {
    overflow-x: hidden;
		background: $black-russian;
  }
}