@import "./icons/icon_codes";

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?6usuql');
  src:  url('../fonts/icomoon.eot?6usuql#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?6usuql') format('truetype'),
    url('../fonts/icomoon.woff?6usuql') format('woff'),
    url('../fonts/icomoon.svg?6usuql#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play_arrow {
	&:before {
		content: $icon-play_arrow;
	}
}
.icon-launchopen_in_new {
	&:before {
		content: $icon-launchopen_in_new;
	}
}
.icon-expand_less {
	&:before {
		content: $icon-expand_less;
	}
}
.icon-expand_more {
	&:before {
		content: $icon-expand_more;
	}
}
.icon-email {
	&:before {
		content: $icon-email;
	}
}
.icon-vpn_key {
	&:before {
		content: $icon-vpn_key;
	}
}
.icon-edit {
	&:before {
		content: $icon-edit;
	}
}
.icon-warning {
	&:before {
		content: $icon-warning;
	}
}
.icon-close {
	&:before {
		content: $icon-close;
	}
}
.icon-settings {
	&:before {
		content: $icon-settings;
	}
}
.icon-exit_to_app {
	&:before {
		content: $icon-exit_to_app;
	}
}
.icon-home {
	&:before {
		content: $icon-home;
	}
}
.icon-check {
	&:before {
		content: $icon-check;
	}
}
.icon-keyboard_control {
	&:before {
		content: $icon-keyboard_control;
	}
}
.icon-more_vert {
	&:before {
		content: $icon-more_vert;
	}
}
.icon-search {
	&:before {
		content: $icon-search;
	}
}
.icon-share {
	&:before {
		content: $icon-share;
	}
}
.icon-feedback {
	&:before {
		content: $icon-feedback;
	}
}
.icon-explore {
	&:before {
		content: $icon-explore;
	}
}
.icon-bookmark {
	&:before {
		content: $icon-bookmark;
	}
}
.icon-bookmark_outlineturne {
	&:before {
		content: $icon-bookmark_outlineturne;
	}
}
.icon-favorite {
	&:before {
		content: $icon-favorite;
	}
}
.icon-favorite_outline {
	&:before {
		content: $icon-favorite_outline;
	}
}
.icon-bookmarks {
	&:before {
		content: $icon-bookmarks;
	}
}
.icon-avatar {
	&:before {
		content: $icon-avatar;
	}
}
.icon-trending {
	&:before {
		content: $icon-trending;
	}
}
.icon-apps {
	&:before {
		content: $icon-apps;
	}
}
.icon-menu {
	&:before {
		content: $icon-menu;
	}
}
.icon-arrow_back {
	&:before {
		content: $icon-arrow_back;
	}
}
.icon-arrow_forward {
	&:before {
		content: $icon-arrow_forward;
	}
}
.icon-thumb_down {
	&:before {
		content: $icon-thumb_down;
	}
}
.icon-thumb_up {
	&:before {
		content: $icon-thumb_up;
	}
}
.icon-close_outline {
	&:before {
		content: $icon-close_outline;
	}
}
.icon-info_outline {
	&:before {
		content: $icon-info_outline;
	}
}
.icon-arrow_back_ios {
	&:before {
		content: $icon-arrow_back_ios;
	}
}
.icon-arrow_forward_ios {
	&:before {
		content: $icon-arrow_forward_ios;
	}
}
.icon-instagram {
	&:before {
		content: $icon-instagram;
	}
}
.icon-discord {
	&:before {
		content: $icon-discord;
	}
}
.icon-facebook {
	&:before {
		content: $icon-facebook;
	}
}
.icon-twitter-with-circle {
	&:before {
		content: $icon-twitter-with-circle;
	}
}
