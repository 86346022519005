.topic-item {
  justify-content: space-between;
}
.topic-item_details {
  position: relative;
  max-width: 700px;
  width: 100%;
  height: 135px;
  background-size: 100% 100%;
  background-blend-mode: color;
}
.topic-item_title {
  font-size: 26px;
  font-weight: bold;
  display: block;
  white-space: nowrap;
  overflow-x: clip;
}
.topic-item_subscription-count {
  color: #E6E6E6;
}
.topic-item_actions {
  position: absolute;
  top: 15px;
  left: 15px;
  max-width: 680px;
  width: 95%;
}
.topic-item_actions .button-group-left {
  float: left;
  margin-right: 10px;
}
.topic-item_actions .button-group-left .back-button {
  font-size: 26px;
  margin-top: 13px;
}
.topic-item_actions .title {
  float: left;
  width: 60%;
  word-wrap: break-word;
  display: flex;
  border-bottom: 0;
}
.topic-item_actions .title:after {
  clear: both;
}
.topic-item_actions .title img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-right: 25px;
}
.topic-item_actions .button-group-right {
  float: right;
}
.topic-item_actions .button-group-right .share-button {
  font-size: 26px;
  margin-right: 25px;
  top: 8px;
}
.topic-item_actions .button-group-right .subscribe-button {
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  height: 40px;
  border-radius: 20px;
  text-transform: none;
  padding: 0 15px;
  background-color: #0066ff;
  margin-top: 6px;
}
.topic-item_actions .button-group-right .unsubscribe-button {
  color: #FFFFFF;
  background-color: #373749;
  min-width: 110px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  height: 40px;
  border-radius: 20px;
  text-transform: none;
  padding: 0 15px;
  margin-top: 6px;
}
.topic-item_actions .button-group-right .unsubscribe-button:before {
  content: "Subscribed";
}
.topic-item_actions .button-group-right .unsubscribe-button:hover:before, .topic-item_actions .button-group-right .unsubscribe-button:active:before {
  content: "Unsubscribe";
}
.topic-item_navigation {
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  max-width: 700px;
  color: #fff;
  border-bottom: 1px solid #3a3a41;
  border-top: 1px solid #3a3a41;
}
.topic-item_navigation button.MuiTab-root {
  max-width: 100px;
  min-width: 100px;
  text-transform: none;
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.7;
}
.topic-item_navigation button.MuiTab-root.Mui-selected {
  font-weight: bold;
  opacity: 1;
}
.topic-item_navigation button.MuiTab-root:hover, .topic-item_navigation button.MuiTab-root:active {
  opacity: 1;
}
.topic-item_navigation span.MuiTabs-indicator {
  background: #FFFFFF;
  height: 3px;
  bottom: 2px;
}

@media screen and (max-width: 1279px) {
  .topic-item_actions .title {
    width: 55%;
  }
  .topic-item_actions .button-group-right .share-button {
    margin-right: 10px;
  }
}
@media screen and (max-width: 1095px) {
  .topic-item_title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 330px;
  }
  .topic-item_actions .button-group-right {
    margin-right: 10px;
  }
  .topic-item_actions img.topic-icon {
    display: none;
  }
}
@media screen and (max-width: 930px) {
  .topic-item_title {
    font-size: 23px;
    width: 230px;
  }
  .topic-item_actions .title {
    width: 45%;
  }
  .topic-item_actions .title img {
    margin-right: 10px;
  }
}
@media screen and (max-width: 500px) {
  .topic-item {
    border-bottom: 1px solid #3a3a41;
  }
  .topic-item_details {
    height: 180px;
  }
  .topic-item_actions {
    top: 10px;
  }
  .topic-item_actions .button-group-left {
    margin-right: 0;
  }
  .topic-item_actions .button-group-left .back-button {
    font-size: 21px;
  }
  .topic-item_actions .title {
    width: 40%;
  }
  .topic-item_actions .button-group-right {
    clear: both;
    float: right;
  }
  .topic-item_actions .button-group-right .share-button {
    margin-right: 5px;
  }
  .topic-item_title {
    font-size: 21px;
    top: 45px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
  }
  .topic-item_navigation button.MuiTab-root {
    max-width: 90px;
    min-width: 60px;
    text-transform: none;
    font-size: 14px;
    line-height: 38px;
  }
}

