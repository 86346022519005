@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slide-in--from-bottom {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes loading-bars {
  0%, 80%, 100% {
    box-shadow: 0 0;
    height: 32px;
  }
  40% {
    box-shadow: -16px;
    height: 40px;
  }
}
.topic_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  animation: fade-in 0.3s ease;
  justify-content: space-between;
  padding: 0 15px;
}
.topic_item {
  position: relative;
  flex-basis: 14.2857142857%;
  margin-bottom: 32px;
}
.topic_item.coming-soon .topic_item--shadow {
  width: 127px;
  position: absolute;
  top: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.topic_item:hover .topic_tooltip {
  opacity: 1;
}
.topic_item--no-data {
  flex-basis: 100%;
  margin: 32px 0;
}
.topic_link, .topic_thumbnail {
  display: block;
  border-radius: 6px;
}
.topic_thumbnail {
  width: 163px;
  margin-bottom: 5px;
}
.topic_thumbnail:hover, .topic_thumbnail:active {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
}
.topic_tooltip {
  font-weight: bold;
  font-size: 14px;
  padding-right: 5px;
}

@media screen and (max-width: 1279px) {
  .topic_thumbnail {
    width: 153px;
  }
}
@media screen and (max-width: 738px) {
  .topic_thumbnail {
    width: 163px;
  }
}

