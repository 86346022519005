.verify-email {
    &_inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        
        margin: 20px;
    }
}