@import '../../../resources/scss/variables';

.membership-setting-page {
  background: $dark-charade;
  border-right: 1px solid $tuna;
  border-left: 1px solid $tuna;
  height: 100%;

  .title {
    font-size: 20px;

    .back-button {
      font-size: 20px;

      .button_label {
        vertical-align: top;
      }
    }

    &-label {
      display: inline-block;
      margin-left: 22%;
    }
  }

  &_inner {
    .membership-type,
    .active-servers {

      &_section {
        .header {
          display: flex;
          justify-content: space-between;
          margin: 0 15px;
          padding: 15px 0;
          border-bottom: 1px solid $tuna;

          .header-section-label {
            font-weight: bold;
          }

          .call-to-action,
          .membership-bill-date {
            color: $silver;
          }

          .call-to-action {
            &.paid {
              padding-top: 8px;
            }
          }
        }

        .details {
          padding: 15px 15px;
          border-bottom: 1px solid $tuna;
          min-height: 50px;

          &_billing {
            margin-top: 15px;
          }

          &_plan, &_billing, &_server {
            color: $silver;

            &-label {
              font-weight: bold;
              color: $white;
            }

            &-type_label {
              color: $silver;
            }

            &-action {
              color: $blue-link;
              cursor: pointer;

              &.right {
                float: right;
              }
            }
          }

          &_plan {
            display: flex;
            justify-content: space-between;
          }

        }
      }
    }

    .details_billing {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .active-servers {
      &_section {
        margin-top:20px;
        border-top: 1px solid $tuna;
      }
    }
  }
}