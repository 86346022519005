#share-dialog .react-share__ShareButton {
  margin-left: 5px;
  margin-right: 5px;
}
#share-dialog .share-modal {
  margin: 0;
  background-color: inherit;
  border-radius: 8px;
}
#share-dialog .share-modal-content {
  background-color: #2b2b31;
  min-width: 200;
  max-width: 300px;
  height: 100%;
  position: relative;
}
#share-dialog .share-modal-title {
  color: #b3b3b3;
  position: absolute;
  top: 15px;
  left: 15px;
  text-transform: capitalize;
}
#share-dialog .share-modal-close {
  color: #b3b3b3;
  background-color: transparent;
  position: absolute;
  top: 5px;
  right: 5px;
}
#share-dialog .share-modal-body {
  clear: both;
  margin-top: 50px;
  text-align: center;
}

