// Global Variables
//-------------------------------------//

// Colors
//-------------------------------------//
$gray1: #121217;
$cod-gray: #121212;
$dark-slate: #131313;
$dark-woodsmoke: #16161D;
$woodsmoke: #141415;
$gray10: #1a1a1a;
$shark: #1d1d26;
$black-russian: #1c1c21;
$jaguar: #23232f;
$slate: #262626;
$dark-charade: #272734;
$bastille: #2b2b31;
$charade: #2c2c3a;
$bastille-light: #303036;
$mine-shaft: #333333;
$revolver: #36363a;
$black-marlin: #3a3a41;
$tuna: #373749;
$gray10: #808080;
$blue12: #1a75ff;
$blue11: #06F;
$blue-link: #0066ff;
$blue: #0066CC;
$light-blue: #349cff;
$ship-cove: #6e96bf;
$green: #008000;
$red: #CC0000;
$pale-pink: #ff0066;
$flame-pea: #E66531;
$silver: #999999;
$pink-swan: #b3b3b3;
$light-slate: #E6E6E6;
$white: #FFFFFF;
$clear: transparent;
$shaded-text: rgba(255,255,255,0.6);
$discord: #4e5af6;
$discord-hover: #3e4ae6;


// Media Query Breakpoints
//-------------------------------------//
$screen--mobile: 480px;
$screen--small: 768px;
$screen--medium: 1367px;
$screen--large: 1920px;


// Max-Width Breakpoints
//-------------------------------------//
$max-width--default: 750px;
$max-width--narrow: 700px;


// Fonts and Line Heights
//-------------------------------------//
$font-family--base: 'Roboto', sans-serif;

$font-weight--light: 300;
$font-weight--normal: 400;
$font-weight--mid-bold: 500;
$font-weight--bold: 600;
$font-weight--black: 800;

$font-size--base: 14px;

$font-size--small: 11px;
$font-size--medium: 18px;
$font-size--icon: 24px;
$font-size--large: 30px;

$line-height--base: 1.3;
$line-height--large: 1.5;


// Common UI Variables
//-------------------------------------//
$grid-unit: 8px;

$border-radius--small: 3px;
$border-radius--medium: 5px;
$border-radius--large: 8px;
$border-radius--circle: 50%;


// Z-Space Management
//-------------------------------------//
$z-index--base: 0;
$z-index--level-one: $z-index--base + 5;
$z-index--level-two: $z-index--base + 10;
$z-index--level-three: $z-index--base + 15; // used for global app header
$z-index--mask: 50;
$z-index--modal-level-one: $z-index--mask + 5;
$z-index--modal-level-two: $z-index--mask + 10;
$z-index--modal-level-three: $z-index--mask + 15; // used for global toast notification


// Animations
//-------------------------------------//
$transition-duration--quick: 0.2s;
$transition-duration--default: 0.3s;
$transition-duration--slower: 0.4s;
$transition-timing-function--default: ease;


// F u n c t i o n s
//-------------------------------------//

// Grid-Unit multiplier
@function gux($multiplier: 1) {
  @return $grid-unit * $multiplier;
}