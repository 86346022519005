@import '../../resources/scss/variables';

#share-dialog {
  .react-share__ShareButton{
    margin-left: 5px;
    margin-right: 5px;
  }


  .share-modal {
    margin: 0;
    background-color: inherit;
    border-radius: 8px;

    &-content {
      background-color: $bastille;
      min-width: 200;
      max-width: 300px;
      height: 100%;
      position: relative;
    }

    &-title {
      color: $pink-swan;
      position: absolute;
      top: 15px;
      left: 15px;
      text-transform: capitalize;
    }

    &-close {
      color: $pink-swan;
      background-color: transparent;
      position: absolute;
      top: 5px;
      right: 5px;
    }

    &-body {
      clear: both;
      margin-top: 50px;
      text-align: center;
    }
  }
}