.article {
  animation: fade-in 0.3s ease;
}
.article .title {
  font-size: 24px;
  border-bottom: 2px solid #373749;
}
.article .title.no-border {
  border-bottom: none;
}
.article_list {
  background: #272734;
  padding-bottom: 16px;
}
.article_list_item {
  background-color: #272734;
  padding: 15px;
  border-bottom: 1px solid #373749;
}
.article_list_item--no-data {
  margin: 32px;
  justify-content: center;
}
.article_list_item:hover, .article_list_item:active {
  background-color: #2c2c3a;
}
.article_item {
  display: flex;
}
.article_item-content {
  position: relative;
}
.article_item-content .content-body .short-body {
  display: none;
  max-width: 640px;
  font-size: 14px;
}
.article_item-content .content-body .content-btn {
  position: absolute;
  font-size: 28px;
  right: 10px;
  top: -20px;
}
.article_item-content .content-body .content-btn:after {
  clear: both;
}
.article_item-content .content-body.show .short-body {
  display: block;
  padding: 15px 15px 0 0;
}
.article_item-content .content-body.show .short-body .read-more {
  padding-left: 10px;
  color: #999999;
  cursor: pointer;
}
.article_thumbnail {
  width: 210px;
  height: 118px;
  border-radius: 10px;
  background-color: #23232f;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  cursor: pointer;
  position: relative;
}
.article_thumbnail_video_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 210px;
  height: 118px;
}
.article_thumbnail_video_overlay_svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.article_details {
  flex: 1;
  margin-left: 12px;
}
.article_title {
  margin: 2px 0 8px;
  font-size: 18px;
  color: #FFFFFF;
  cursor: pointer;
}
.article_title:focus {
  text-decoration: underline;
  outline: none;
}
.article_action {
  color: #999999;
  cursor: pointer;
  font-size: 14px;
}
.article_action.share-btn, .article_action.bookmark-btn {
  font-size: 16px;
  vertical-align: middle;
  margin-left: 5px;
  width: 25px;
  height: 25px;
}
.article_action.bookmark-btn {
  font-size: 20px;
  margin-left: 3px;
}
.article_action.bookmark-btn.active {
  color: #0066CC;
}
.article_action.launch-btn {
  font-size: 20px;
  top: 3px;
}
.article_action:hover, .article_action:active {
  color: #FFFFFF;
}
.article_action--date {
  cursor: initial;
  margin-left: 8px;
}
.article_action--date:hover, .article_action--date:active {
  color: #999999;
}
.article_action--date:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: #999999;
  vertical-align: middle;
}
.article_action--topic {
  color: #999999;
  font-weight: 400;
}
.article_action--topic:hover, .article_action--topic:active {
  color: #FFFFFF;
}

@media only screen and (max-width: 1008px) {
  .article_list {
    border: none;
    border-radius: 0;
  }
  .article_item {
    display: block;
  }
  .article_thumbnail {
    width: 100%;
    height: 160px;
    background-size: cover;
    background-position: 0 50%;
  }
  .article_details {
    margin: 10px 0 5px;
  }
}
@media screen and (max-width: 738px) {
  .article .title--section {
    font-size: 18px;
    padding: 15px 10px 0 15px;
    border-bottom: 1px solid #3a3a41;
    height: 50px;
    background: #1c1c21;
  }
  .article_list {
    background: #1c1c21;
  }
  .article_list_item {
    background-color: #1c1c21;
  }
}
@media screen and (min-width: 1280px) {
  .article .title--section {
    display: none;
  }
}

