@import '../../resources/scss/variables';

.error-page {
  height: 100%;
  background: $bastille;

  .error-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;

    &_icon {
      font-size: 45px;
      margin: -80px 0 15px 0;
      color: $flame-pea;
    }

    &_message {
      font-size: 16px;
    }
  }
}