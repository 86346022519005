@import '../../resources/scss/variables';

.subscriptions {
  height: 210px;

  &--scroll {
    overflow-y: scroll;
  }

  &::-webkit-scrollbar {
    width: 10px !important;
  }

  .subscriptions_list {
    .subscription_item {
      margin-bottom: 5px;
      cursor: pointer;

      &--no-data {
        margin-left: 15px;
        color: $pink-swan;
      }

      a {
        padding: 10px 0 6px 10px;
        display: block;

        &:hover {
          background: $jaguar;
          border-radius: 10px 0 0 10px;
        }

        &:hover, &:active, &:visited {
          text-decoration: none;
        }

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 15px;
          display: inline-block;
        }

        label {
          text-decoration: none;
          font-size: 16px;
          vertical-align: top;
          line-height: 30px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 170px;
          display: inline-block;
          cursor: pointer;
        }

      }


    }
  }
}

@media screen and (max-width: 1279px) {
  .subscriptions {
    .subscriptions_list {
      .subscription_item {
        a {
          label {
            max-width: 140px;
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1008px) {
  .subscriptions {
    .subscriptions_list {
      .subscription_item {
        a {
          label {
            max-width: 100px;
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 734px) {
  .subscriptions {
    .subscriptions_list {
      .subscription_item {
        &--no-data {
          display: none;
        }

        a {
          label {
            max-width: 100px;
            width: 100%;
            font-size: 14px;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 738px) {
  .subscriptions {
    padding: 0;
    overflow-x: hidden;

    .subscriptions_list {
      .subscription_item {
        &--no-data {
          display: none;
        }

        .subscription_tooltip {
          display: none;
        }

        a {
          &:active, &:hover {
            border-radius: initial;
          }

          img {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}