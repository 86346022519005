@import '../../resources/scss/variables';



// .discord-login-button:hover {
//     background-color: $discord-hover;
// }

.login-divider {
    display: flex;
    align-items: center;
    text-align: center;
    color: $silver;
    font-size: 12px;
    
    span {
      margin: 15px;
    }
}

.login-divider::before,
.login-divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid $tuna;
}

.login-modal {
  &_inner {
    display: grid;
    justify-content: left;
    grid-template-rows: 1fr auto;
    padding-left: 75px;
    padding-right: 75px;

    margin-bottom: 40px;


    text-align: center;

    .error_message {
      color: $red;
      margin: 10px 0 10px;
    }
  }

  &_title {
    font-size: 20px;
    font-weight: $font-weight--bold;
    justify-self: left;
  }

  &_disclaimer {
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left;

    .tattle-link {
        color: $light-blue;
    }
  }

  &_form {
    width: 100%;

    .button {
      width: 100%;
      margin-top: $grid-unit;
      height: 40px;
      border-radius: 10px;
      text-transform: none;
      font-weight: bold;
    }

    .field_input {
      background: $woodsmoke;
      height: 50px;
      border-radius: 10px;
      line-height: 40px;
    }

    .password-requirements {
      font-size: 12px;
      color: $silver;
      text-align: left;
      margin-bottom: 15px;
    }
  }

  &_message {
    margin-top: gux(3);
    font-size: 14px;
    text-align: center;

    .link {
      font-weight: bold;
      color: $light-blue;
    }
  }

  &_response {
    margin: gux(2) 0;
    text-align: center;
    font-style: italic;
  }

	&_register-terms {
		font-size: 12px;
		margin: gux(3) 0 gux(2);

		.link {
			display: block;
			font-size: 14px;
		}
	}
}

@media screen and (max-width: 738px) {
  .login-modal {
    &_message {
      .link {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .login-modal {
    &_inner {
      padding: 10px;
    }
  }
}