@import '../../../resources/scss/variables';

$input-height: gux(4);

.field {
  display: inline-block;
  vertical-align: top;
  text-align: left;

  &:not(:last-child) {
    margin-bottom: $grid-unit;
  }

  &_label {
    display: block;
    margin-bottom: calc($grid-unit / 2);
  }


  &_input {
    display: inline-block;
    width: 100%;
    height: gux(4);
    padding: 0 gux(2);
    border: 2px solid $woodsmoke;
    border-radius: $border-radius--medium;
    background: $cod-gray;
    font-family: $font-family--base;
    font-weight: $font-weight--normal;
    color: $white;
    line-height: $input-height;

    &::placeholder {
      font-family: $font-family--base;
    }

    &:focus {
      outline: none;
      border-color: $blue;
    }

    &:read-only {
      color: rgba($white, 0.3);
    }

    &[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &__error {
      border: 1px solid #ff0000;
    }
  }

  textarea {
    min-height: 100px;
    max-height: 300px;
    resize: vertical;
  }

  &--full {
    width: 100%;
  }

  &--half {
    width: 50%;
  }

  &--third {
    width: 33.333%;
  }

  .field-input-wrapper {
    position: relative;
    .password-visibility {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

}