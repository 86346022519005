@import '../../resources/scss/variables';
@import '../../resources/scss/animations';
@import '../../resources/scss/mixins';

.modal {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fade-in $transition-duration--default $transition-timing-function--default;
  animation-fill-mode: both;
  z-index: $z-index--modal-level-one;

  &_mask {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: rgba($dark-slate, 0.85);
    z-index: $z-index--modal-level-one + 1;
  }

  &_container {
    position: relative;
    width: 500px;
    border-radius: $border-radius--large;
    background-color: $dark-charade;
    z-index: $z-index--modal-level-one + 2;
    display: grid;
    &:focus {
      outline: none;
    }
  }

  &_header {
    display: grid;
    grid-template-columns: 9fr 1fr;
    align-items: center;
    justify-content: center;
    height: 75px;
  }

  &_title {
    font-size: 16px;
    font-weight: bold;
    margin-left: 15px;
  }

  &_exit {
    margin: 24px;
    font-size: 24px;
    color: $pink-swan;
  }

  &--hiding {
    animation-name: fade-out;
  }

  &--slide-up & {
    &_container {
      animation: slide-in--from-bottom $transition-duration--quick $transition-timing-function--default;
    }
  }
  
}

@media screen and (max-width: 1008px) {
  .modal {
    &_container {
      overflow-y: scroll;
      max-height: 82vh;
    }
  }
}

@media screen and (min-height: 738px) {
  .modal {
    &_container {
      overflow-y: hidden;
      max-height: initial;
    }
  }
}


@media screen and (max-width: 600px) {
  .modal{ 
    &_container {
      width: 90%;
    }
  }
}