@import '../../resources/scss/variables';

.privacy-page {
  background: $dark-charade;
	border-right: 1px solid $tuna;
	border-left: 1px solid $tuna;

  .doc-body-container {
    width: 100%;
    border-radius: 5px;
    min-height: 94vh;
    padding: 0 16px 25px;

    h2 {
      padding: 15px 0;
    }

    .sub-title {
      font-size: 14px;
      padding: 0 0 20px;
    }

    .body-section {
      font-size: 14px;
      line-height: 20px;
    }

    .contact-email {
      font-size: 14px;
      display: block;
      margin: 25px 0 5px;
    }
  }
}

