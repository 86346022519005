
@import '../../resources/scss/variables';

.email-verification_container {
    font-size: small;
    text-align: center;
    margin-bottom: 10px;

    button {
        cursor: pointer;
        color: $blue-link;
        background: none!important;
        border: none;
        padding: 0!important;
    }
}

@media screen and (max-width: 738px) {
    .email-verification_container {
        span{
            display: none;
        }
    }
}