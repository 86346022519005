@import 'variables';
@import "customIcons";

*,
*:before,
*:after {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $jaguar;
  //border-radius: 2px;
  background-color: $jaguar;
}

::-webkit-scrollbar-thumb {
  //border-radius: 2px;
  background-color: $black-marlin;
}

html,
body {
  height: 100%;
  font-family: $font-family--base;
  font-weight: $font-weight--normal;
  font-size: $font-size--base;
  line-height: $line-height--base;
  background-color: $gray1;
  color: $white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

body {
  margin: 0;
}

h1, h2, h3, h4 {
  margin: 0;
  font-weight: $font-weight--normal;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;

  &:focus {
    outline: none;
    text-decoration: underline;
  }
}

input, textarea, button {
  font-family: $font-family--base;
  font-size: $font-size--base;
}

input,
textarea {
  border: none;
  outline: none;
}

.link {
  color: $blue;
  transition: color $transition-duration--default $transition-timing-function--default;
  cursor: pointer;

  &:hover {
    color: darken($blue, 10%);
  }
}

.overflow-hidden {
  overflow: hidden;
}

.hidden {
  display: none;
}

// globally remove clear icon from input field in IE
::-ms-clear {
  display: none;
}

.large-icon {
  width: 28px;
  height: 28px;
  font-size: 28px;
  line-height: 28px;
}

.medium-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
}

.small-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
  line-height: 18px;
}

@media screen and (max-width: 736px) {
  body {
    height: 100vh;
  }
}

// Toast container CSS

.MuiSnackbarContent-root {
  background-color: $blue !important;
}

.MuiSnackbarContent-message {
  font-size: 14px;
}