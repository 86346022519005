@import '../../resources/scss/variables';

.article-detail {
  background: $dark-charade;
  height: 100%;

  &_header {
    display: flex;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    background: $dark-charade;

    .header_back-button {
      color: $white;
      font-size: 25px;
      width: 40px;
      padding: 5px;
      margin: 7px;
    }

    .header_action3 {
      text-align: center;
      line-height: 60px;

      a, button {
        font-size: 23px;
        margin-right: 10px;

        &:hover, &:active,&:visited {
          text-decoration: none;
        }
      }
    }
  }

  .article-frame {
    height: calc(100% - 55px);
		background-color: $white;
  }
}

@media screen and (max-width: 738px) {
  .article-detail {
		min-height: 600px;
    background: $black-russian;

    &_header{
      background: $black-russian;

      .header_feed-title {
        max-width: 90px;
        overflow: hidden;
        visibility: hidden;
      }
    }
  }
}